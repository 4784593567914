import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import { Clear, Tune } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { RfqContext } from "../../../context/RfqContext";
import { units } from "../../../utils/unitSelector";

const ProductDetailsAdd = ({
  item,
  index,
  handleInputChange,
  removeProduct,
}) => {
  const [count, setCount] = useState(1);
  // const maxGuests = 99;
  const minGuests = 1;
  const today = new Date().toISOString().split("T")[0];
  const handleCount = (e) => {
    if (e < minGuests) {
      setCount(minGuests);
      handleInputChange(item.id, "quantity", minGuests);
    } else {
      setCount(e);
      handleInputChange(item.id, "quantity", e);
    }
  };

  const decrementCount = () => {
    if (count > minGuests) {
      setCount(count - 1);
      handleInputChange(item.id, "quantity", count - 1);
    }
  };

  const incrementCount = (item) => {
    setCount(count + 1);
    handleInputChange(item.id, "quantity", count + 1);
  };

  return (
    <div className="pdt_Detail">
      <h6 style={{ color: "#000" }} className="text-capitalize">
        {index + 1}. {item.catalog?.name}{" "}
        <span className="text-capitalize">
          {"["} Product Average Price : ₹ {item?.catalog?.average_price?.price}{" "}
          / {item?.catalog?.average_price?.unit} {"]"}
          {/* {data.catalog && data.catalog?.category_id?.name} */}
        </span>
        <span
          style={{ display: "inline-block", marginLeft: "auto" }}
          onClick={() => removeProduct(item)}
        >
          <Clear />
        </span>
      </h6>

      <div className="product-qty">
        <div>
          <div className="uni-heading">Enter Quantity * Unit</div>
          <div className="product-qty-input">
            <button type="button" onClick={() => decrementCount(item)}>
              <RemoveIcon />
            </button>
            <input
              className="show-qty"
              onInput={(e) => {
                handleCount(Number(e.target.value), item);
              }}
              name="quantity"
              type="number"
              value={item?.quantity || count}
              required
            />
            <button type="button" onClick={() => incrementCount(item)}>
              <AddIcon />
            </button>
          </div>
        </div>
        <div>
          <div className="uni-heading">Enter Your Price / Per Unit</div>
          <div>
            <input
              type="number"
              name="price"
              required
              defaultValue={item?.price}
              onInput={(e) =>
                handleInputChange(item.id, "price", e.target.value)
              }
            />
          </div>
        </div>
        <div>
          <div className="uni-heading text-nowrap">Select Unit</div>
          <div className="product-qty-input">
            <select
              name="unit"
              required
              className="form-select"
              onChange={(e) =>
                handleInputChange(item.id, "unit", e.target.value)
              }
            >
              <option value={""}>select</option>
              {units(item.catalog?.state).map((unit) => {
                return (
                  <option selected={item?.unit == unit} value={unit}>
                    {unit}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div>
          <div className="uni-heading">Estimate Delivery Date</div>
          <div className="product-qty-input">
            <input
              name="estimate_delivery_date"
              required
              className="form-select"
              defaultValue={item?.estimate_delivery_date}
              type="date"
              min={today}
              onChange={(e) =>
                handleInputChange(
                  item.id,
                  "estimate_delivery_date",
                  e.target.value
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const FormStep3 = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const { rfq_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const navigate = useNavigate();

  const token = localStorage.getItem("token_web");

  const fetchApi = async () => {
    const toastID = toast.loading("Fetching...");
    const response = await fetch(`${baseUrl}/rfq/${id}/draft`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    toast.dismiss(toastID);
    setSelectedProducts(() => result.data?.added_product);
  };

  const submitRfq = async (e) => {
    e.preventDefault();

    const productDetails = selectedProducts.map(
      ({ id, price, quantity, unit, estimate_delivery_date }) => ({
        id,
        price,
        unit,
        estimate_delivery_date,
        quantity: quantity || 1,
      })
    );

    const response = await fetch(`${baseUrl}/rfq/${id}/addProductsDetails`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productDetails),
    });

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    navigate(`/rfqstep4/${rfq_id}?id=${id}`);
  };

  const handleInputChange = (productId, field, value) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, [field]: value } : product
      )
    );
  };

  const removeProduct = async (item) => {
    if (item.catalog) {
      await (
        await fetch(`${baseUrl}/rfq/${item.id}/remove`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      ).json();
    }

    const newProducts = selectedProducts.filter((val) => item.id !== val.id);
    setSelectedProducts(newProducts);

    if (newProducts.length === 0) {
      navigate(`/rfqstep2/${rfq_id}?id=${id}`);
    }
  };

  function back() {
    navigate(`/rfqstep2/${rfq_id}?id=${id}`);
  }

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <NavbarAuth />
      <section className="profile_page">
        <h1>RFQ Form</h1>
        <p className="text-center">
          Effortlessly submit your RFQ, include desired chemicals. Simplify the
          process, receive competitive quotes from trusted suppliers.
        </p>

        <ul className="summary step2 step3" style={{ padding: "20px 0" }}>
          <li>Basic Details</li>
          <li>Product Request</li>
          <li>Product Request Details</li>
          <li>Seller Selection</li>
          <li>Review Form</li>
        </ul>
        <form onSubmit={submitRfq}>
          {selectedProducts &&
            selectedProducts.length > 0 &&
            selectedProducts.map((item, index) => (
              <ProductDetailsAdd
                item={item}
                index={index}
                handleInputChange={handleInputChange}
                removeProduct={removeProduct}
              />
            ))}
          <div
            className="form-control"
            style={{ margin: "0 0 auto auto", width: "50%" }}
          >
            <button className="cancel" type="button" onClick={back}>
              Cancel
            </button>
            <button className="continue" type="submit">
              Continue
            </button>
          </div>
        </form>
      </section>

      <Footer />
    </>
  );
};

export default FormStep3;
