import React, { useState, useEffect } from "react";

import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import "./buyers.css";
import ProductHistory from "../Components/ProductHistory/ProductHistory";
import Img from "../../../assets/product.png";
import ImgFilter from "../../../assets/arrowDown-color-Filter.png";
import ImgsearchIcon from "../../../assets/search.png";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import { borderRadius } from '@mui/system';
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
// import PriceRangeApi from './priceRange';
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import Pagination from "../../../component/Pagination";
import Moment from "react-moment";
import Greeting from "../../../component/Greeting";

// import {useEffect} from "react";

function BuyersHistory() {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [from, setFrom] = useState(1);

  const [value, onChange] = useState(new Date());
  const [valueN, setValue] = React.useState([1000, 10000]);
  const rangeSelector = (event, newValue) => {
    setValue(newValue);
  };

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/orders?page=${currentPage}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    setData(result.data.data);
    setFrom(() => result.data.from);
    setLastPage(result.data.last_page);
  };

  useEffect((useEffect) => {
    $(".ConfirnFilt").click(function () {
      $(".Allfilterinner").slideDown("fast");
      $(".hideRageSliderDivfix").show();
    });
    $(".hideRageSliderDivfix, .history-btn").click(function () {
      $(".Allfilterinner").slideUp("fast");
      $(".hideRageSliderDivfix").hide();
    });
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchApi();
  }, [currentPage]);

  return (
    <section className="buyers-section">
      <NavbarAuth />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={2} />
        </div>
        <div className="bsd-right bsd-right8247">
        <div className="px-3 pt-3">
            <Greeting />
          </div>

          <div className="Outer-bsd-right8247">
            <div className="searchPendingItem" style={{ marginBottom: "20px" }}>
              {/* <input
                type="text"
                placeholder="Search Product With Their Invoice Number, Product Name "
              /> */}
              {/* <img src={ImgsearchIcon} alt="d" /> */}
            </div>
            <div className="hideRageSliderDivfix"></div>
            <div className="table table-responsive w-100">
              <table className="table table-gray" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>ID</th>
                    <th style={{ textAlign: "left" }}>Product</th>
                    <th style={{ textAlign: "left" }}>Seller</th>
                    <th style={{ textAlign: "left" }}>Status</th>
                    <th style={{ textAlign: "left" }}>Order Date</th>
                    <th style={{ textAlign: "left" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index} style={{ padding: "10px" }}>
                        <td
                          style={{ textAlign: "left" }}
                          className="text-capitalize"
                        >
                          {item.order_id}
                        </td>
                        <td
                          style={{ textAlign: "left" }}
                          className="text-capitalize"
                        >
                          {item.product?.name}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.seller?.vendor?.company}
                        </td>
                        <td
                          style={{ textAlign: "left" }}
                          className="text-capitalize"
                        >
                          {item.order_status}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Moment format="D-MMMM-Y hh:mm a">
                            {item.created_at}
                          </Moment>
                        </td>
                        <td style={{ textAlign: "left", padding: 10 }}>
                          <Link to={`${item.id}/show`}>View</Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                handlePageChange={handlePageChange}
                lastPage={lastPage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BuyersHistory;
