import './productdetails.css';
import Navbar from '../Components/Navbar/Navbar';
import BuyersSidebar from '../Components/BuyerSidebar/BuyersSidebar';
import ImgsearchIcon from '../../../assets/search.png';
import { Add, AddSharp, ExpandMore, PlusOneTwoTone, Search, Upload, UploadFile } from '@mui/icons-material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const AddProducts = () => {
    return (
        <>

            <section className="buyers-section">
                <Navbar />
                <div className="buyers-section-div">
                    <div className="bsd-left">
                        {/* <BuyersSidebar n={7} /> */}
                        <BuyersSidebar />
                    </div>
                    <div className="bsd-right addpdt profile_page">
                        <form>
                            <h4>General Information</h4>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Product Name</label>
                                    <input type='text' name='name' placeholder='Ex Camline 50 mg super glue' />
                                    <Search />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Brand</label>
                                    <select className='form-select'>
                                        <option selected>Ex Camlin</option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Category</label>
                                    <select className='form-select'>
                                        <option selected>Ex Adhesive or Chemical</option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Sub Category</label>
                                    <select className='form-select'>
                                        <option selected>Ex Camlin </option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                            </div>
                            <div className="form-control mb-4">
                                <label>Description</label>
                                <textarea rows='6' placeholder='Type Here ' />
                            </div>
                        </form>
                        <form>
                            <h4>Price Information</h4>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Product Price</label>
                                    <input type='text' name='name' placeholder='Ex Camline 50 mg super glue' />
                                    <Search />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Product GST</label>
                                    <select className='form-select'>
                                        <option selected>Ex Camlin</option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Default Price</label>
                                    <select className='form-select'>
                                        <option selected>Ex Adhesive or Chemical</option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Minimum Price For RFQ</label>
                                    <select className='form-select'>
                                        <option selected>Ex Camlin </option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Maximum Price for RFQ</label>
                                    <input type='text' name='price' />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Landed Cost</label>
                                    <input type='text' name='cost' />
                                </div>
                            </div>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Check If You Want To Enable Auto RFQ Replies" />
                            </FormGroup>
                        </form>
                        <form>
                            <h4>Inventory</h4>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>SKU</label>
                                    <input type='text' name='name' />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Stock Availability</label>
                                    <select className='form-select'>
                                        <option selected>Ex Camlin</option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Inventory Management</label>
                                    <select className='form-select'>
                                        <option selected>Ex Adhesive or Chemical</option>
                                        <option value='1'>Designation 1</option>
                                        <option value='2'>Designation 2</option>
                                        <option value='3'>Designation 3</option>
                                    </select>
                                    <ExpandMore />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Relatable Product</label>
                                    <input type='text' name='product' />
                                    <Search />
                                </div>
                            </div>
                        </form>
                        <form>
                            <h4>Product Images</h4>
                            <div className="form-control mb-4">
                                <label>Primary image</label>
                                <input type='file' name='url' />
                                <Upload className='icon' />
                            </div>
                            <div className="form-control mb-4">
                                <label>Other Images</label>
                                <input type='file' name='title' />
                                <Upload className='icon' />
                            </div>
                        </form>

                        <form>
                            <h4>SEO</h4>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>URL</label>
                                    <input type='text' name='url' placeholder='Ex www.google.com/facebook' />
                                </div>
                                <div className="form-control mb-4">
                                    <label>Meta Title</label>
                                    <input type='text' name='title' />
                                </div>
                            </div>
                            <div className="form-control mb-4">
                                <label>Meta Decription</label>
                                <textarea rows='6' placeholder='Type Here ' name='decription' />
                            </div>
                        </form>
                        <form>
                            <h4>Add Attributes</h4>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Attributes</label>
                                    <input type='text' name='attributes' />
                                </div>
                                <div className="form-control mb-4">
                                    <label>value</label>
                                    <input type='text' name='value' />
                                </div>
                            </div>
                            <button className='add'>Add More <Add /></button>
                        </form>
                        <form>
                            <h4>Related Products</h4>
                            <div className="d-flex">
                                <div className="form-control mb-4">
                                    <label>Attributes</label>
                                    <input type='text' name='attributes' />
                                </div>
                                <div className="form-control mb-4">
                                    <label>value</label>
                                    <input type='text' name='value' />
                                </div>
                            </div>
                            <button className='add'>Add More <Add /></button>
                        </form>

                        <div className="form-control mt-4">
                            <button className='cancel'>Cancel</button>
                            <button className='continue'>Create</button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AddProducts