import "./landing.css";
import "./sidebar.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Limg1 from "../../assets/WriteUs.png";
// Landing Images

import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../utils/toasterUpdate";
import { baseUrl } from "../../utils/baseUrl";
import { NavbarAuth } from "../dashboard/Components/Navbar/Navbar";

function ContactNew() {
  const [Val, setVal] = useState(false);
  const [isSignup, setSignup] = useState(0);
  function No(index) {
    setSignup(index);
  }

  const [contact, setContact] = useState({
    name: "",
    email: "",
    company: "",
    description: "",
  });

  const formRef = useRef();

  const submitApi = async (e) => {
    e.preventDefault();

    const toastID = toast.loading("Sending...");
    const response = await fetch(`${baseUrl}/contact`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...contact }),
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    formRef.current.reset();
    toasterUpdate(toastID, result.message, "success");
  };

  const token = localStorage.getItem("token_web");


  return (
    <>
      {/* NAVBAR + SECTION-1 */}
      <section className="landing-sec-2New">
      {token ? <NavbarAuth /> : <Navbar />}


        <div className="contactUsOuter">
          <div className="contactUsInner">
            <div className="ConatctleftSec">
              <h2>Contact us</h2>
              <p>
                Thank you for considering Rasayankart as your partner in
                chemical procurement. We are committed to providing you with
                top-notch service and support.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="ContactContentSection ContactContentSection2">
        <div className="ContactContentSectionRight ContactContentSectionRightnew2">
          <div className="contactFormTop">
            <div>
              <h4>FOR GENERAL INQUIRIES:</h4>
              <p>
                Email:{" "}
                <a href="mailto:info@rasayankart.in" target="_blank">
                  info@rasayankart.in
                </a>
              </p>
              <p>Phone: <small>Will be updated soon</small></p>
              <p class="text-nowrap">
                Office Hours: Monday-Friday, 9:00 AM - 7:00 PM (IST)
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ContactContentSection ContactContentSection2">
        <div className="ContactContentSectionRight ContactContentSectionRightnew2">
          <div className="contactFormTop">
            <div>
              <h4>CUSTOMER SUPPORT:</h4>
              <p>
                Email:{" "}
                <a href="mailto:info@rasayankart.in" target="_blank">
                  info@rasayankart.in
                </a>
              </p>
              <p>Phone: <small>Will be updated soon</small></p>
              <p>
                Raise ticket :{" "}
                <a
                  href="mail:info@rasayankart.in?subject=Order relat"
                  target="_blank"
                >
                  Raise a ticket for any support related to Orders.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="ContactContentSection ContactContentSection2">
        <div className="ContactContentSectionRight ContactContentSectionRightnew2">
          <div className="contactFormTop">
            <div>
              <h4>SALES AND PARTNERSHIPS:</h4>
              <p>Explore partnership opportunities and bulk orders.</p>
              <p>
                Email:{" "}
                <a href="mail:info@rasayankart.in" target="_blank">
                  info@rasayankart.in
                </a>
              </p>
              <p>Phone: <small>Will be updated soon</small></p>

              <p>
                <b>Address:</b>
                <ol>
                  <li>
                    Ahmedabad Office : C-703, Titanium City Centre, Anandnagar
                    Road, Satellite, Ahmedabad, Gujarat, India – 380015
                  </li>
                  <li>Hyderabad office : </li>
                  <li>Mumbai Office : </li>
                  <li>Delhi Office :</li>
                </ol>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="ContactContentSection ContactContentSection2">
        <div className="ContactContentSectionRight ContactContentSectionRightnew2">
          <div className="contactFormTop">
            <div>
              <h6>CONNECT WITH US:</h6>
              <p>
                Follow us on social media for the latest updates and industry
                insights:
              </p>

              <div className="d-flex gap-3">
                 <a href="javascript:void(0)" className=""><i className="fab fa-2x fa-linkedin"></i></a>
                 <a href="javascript:void(0)" className=""><i className="fab fa-2x fa-facebook"></i></a>
                 <a href="javascript:void(0)" className=""><i className="fab fa-2x fa-instagram text-danger"></i></a>
                 <a href="javascript:void(0)" className=""><i className="fab fa-2x fa-twitter text-info"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ContactContentSection ContactContentSection2">
        <div className="ContactContentSectionRight ContactContentSectionRightnew2">
          <div className="contactFormTop">
            <img src={Limg1} alt="icon" />
            <p>
              We value your feedback and inquiries. Feel free to reach out, and
              our dedicated team will assist you promptly.
            </p>
          </div>
          <form
            onSubmit={submitApi}
            className="reach-form mx-auto"
            ref={formRef}
          >
            <div className="d-flex">
              <input
                type="text"
                required
                onInput={(e) =>
                  setContact({ ...contact, name: e.target.value })
                }
                placeholder="Name*"
                name="Name"
              />
              <input
                type="email"
                required
                onInput={(e) =>
                  setContact({ ...contact, email: e.target.value })
                }
                placeholder="Email*"
                name="email"
              />
            </div>

            <div className="d-flex mb-2">
              <input
                type="text"
                required
                onInput={(e) =>
                  setContact({ ...contact, company: e.target.value })
                }
                className="w-100"
                placeholder="Company*"
                name="company"
              />
            </div>

            <textarea
              name="description"
              id=""
              required
              onInput={(e) =>
                setContact({ ...contact, description: e.target.value })
              }
              placeholder="Description*"
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactNew;
