import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList,
} from "recharts";
import user from "../../../assets/users.png";

const Slider = () => {
  const data = [
    {
      name: "2020",
      label: "2Cr",
      Profit: 2,
    },
    {
      name: "2021",
      label: "3Cr",
      Profit: 3,
    },
    {
      name: "2022",
      label: "5Cr",
      Profit: 5,
    },
    {
      name: "2023",
      label: "8Cr",
      Profit: 8,
    },
  ];

  return (
    <>
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          350: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 1,
          },
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="graph">
            <p>Profit Over the Years</p>
            <h2>
              60% <small>Last Year</small>
            </h2>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart width={150} height={40} data={data}>
                <XAxis dataKey="name" />
                {/* <YAxis /> */}
                <Legend />
                <Bar dataKey="Profit" fill="#042D5F">
                  <LabelList
                    fill="#ffffff"
                    strokeOpacity="0"
                    dataKey="label"
                    position="insideTop"
                    angle="0"
                  />
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          {/* <div className="topslide  d-flex">
            <img
              src={user}
              alt={user}
              style={{ width: "50px", height: "50px" }}
            />
            <div className="d">
              <p style={{ color: "#006FF2", padding: "0", margin: "0" }}>
                Malala Sheikh
              </p>
              <span style={{ color: "#CACACA" }}>Entrepreneur</span>
              <p style={{ color: "#000", padding: "0" }}>
                Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                vulputate libero et velit interdum, ac aliquet odio mattis.
              </p>
            </div>
          </div>
          <div className="topslide bottomslide">
            <p style={{ color: "#006FF2", padding: "0", margin: "0" }}>
              Malala Sheikh
            </p>
            <span style={{ color: "#CACACA" }}>Entrepreneur</span>
            <p style={{ color: "#000", padding: "0" }}>
              Norem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
              vulputate libero et velit interdum, ac aliquet odio mattis.
            </p>
          </div> */}
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Slider;
