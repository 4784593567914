import "./done.css";
import DoneImg from "../../../assets/complete.png";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import { BrowserRouter as Router, Link } from "react-router-dom";
import Footer from "../../landing_page/Footer";

function Done() {
  return (
    <>
      <section className="place-sec-1">
        <NavbarAuth />
        <div className="checkout-done">
          <div className="cd-top">
            <img src={DoneImg} alt=" " />
            <h1>Thank you your order has been placed</h1>
            <p>
              Seamless chemical trade journey continues with Rasayan Kart. Stay
              tuned for more opportunities and collaborations.
            </p>
          </div>
          <div className="cd-bottom cd-bottom2">
            <Link className="cd-bottom-c" to="/orders">
              Go To My Orders{" "}
            </Link>
            <Link className="cd-bottom-c Shop-More" to="/shop">
              Shop More
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Done;
