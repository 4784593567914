import "./buyers.css";
import Navbar from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import { HalfPieChart } from "half-pie-chart";
import React, { Component } from "react";
import RKWallet from "./RKWallet";
import { FileCopy, WalletRounded } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Link } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { useState } from "react";
import { useEffect } from "react";
import formatter from "../../../utils/formatter";
import Moment from "react-moment";
import Greeting from "../../../component/Greeting";

function BuyersWallet() {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [data, setData] = useState({});
  const [transaction, setTransaction] = useState([]);

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/wallet?page=${currentPage}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    setData(() => result.data);
    setTransaction(result.data.transaction?.data);
    setLastPage(result.data.transaction?.last_page);
  };

  useEffect(() => {
    fetchApi();
    return () => toast.dismiss();
  }, []);

  return (
    <section className="buyers-section">
      <Navbar />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={4} />
        </div>
        <div className="bsd-right">
          <Greeting />
          <div className="rkwallet">
            <div className="widgets">
              <div className={"wallet1"}>
                <p className="type">
                  RK Wallet Credit <ErrorOutlineIcon />
                </p>
                <h6>
                  <WalletRounded />
                </h6>
                <h1>{formatter.format(data.wallet?.sanctioned_amount || 0)}</h1>
                {/* <Link>{val.transaction}</Link> */}
              </div>
              <div className={"wallet2"}>
                <p className="type">
                  Available Credit <ErrorOutlineIcon />
                </p>
                <h6>
                  <WalletRounded />
                </h6>
                <h1>{formatter.format(data.wallet?.balance || 0)}</h1>
                {/* <Link>{val.transaction}</Link> */}
              </div>
              <div className={"wallet3"}>
                <p className="type">
                  Used Credit <ErrorOutlineIcon />
                </p>
                <h6>
                  <WalletRounded />
                </h6>
                <h1>
                  {formatter.format(
                    data.wallet?.sanctioned_amount - data.wallet?.balance
                  ) || 0}
                </h1>
                {/* <Link>{val.transaction}</Link> */}
              </div>
              <div className={"wallet4"}>
                <p className="type">
                  Last Transact Amount <ErrorOutlineIcon />
                </p>
                <h6>
                  <WalletRounded />
                </h6>
                <h1>
                  {formatter.format(
                    data.wallet?.last_transaction_cred?.amount || 0
                  )}
                </h1>
                {/* <Link>{val.transaction}</Link> */}
              </div>
            </div>

            <div className="searchPendingItem" style={{ margin: "20px 0" }}>
              <input type="text" placeholder="Search by Transaction Number" />
              <span>Search</span>
            </div>
            {/* <div className="flex-box justify-content-end">
              <button>Withdraw Amount</button>
            </div> */}
            <div
              className="table-parent mt-5"
              id="wallet-table"
              style={{ MarginTop: "40px" }}
            >
              <table className="table">
                <tr className="bg-light">
                  <th className="">Transaction ID</th>
                  <th className="">Amount</th>
                  <th className="">Status</th>
                  <th className="">Date</th>
                </tr>
                {transaction.map((item, index) => (
                  <tr className="" key={index}>
                    <td className="rit3-info">{item.id}</td>
                    <td className="rit3-info">
                      {formatter.format(item.amount)}
                    </td>
                    <td className="rit3-info">
                      {item.type}
                    </td>
                    <td className="rit3-info">
                      <Moment className="p-0" format="D-MMMM-Y hh:mm a">
                        {item.created_at}
                      </Moment>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BuyersWallet;
