import React, { useRef, useState } from "react";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import { FileUpload } from "@mui/icons-material";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import countryKYCFields from "../../../utils/kyc";
import { useEffect } from "react";

const ProfileStep3 = () => {
  const [profile, setprofile] = useState("");
  const [pan, setpan] = useState("");
  const [gst, setgst] = useState("");
  const [aadhaar, setaadhaar] = useState("");
  const [drug_licence, setdrug_licence] = useState("");
  const [explosive_licence, setexplosive_licence] = useState("");
  const [prohibition, setprohibition] = useState("");
  const [country, setcountry] = useState({ label: "India", value: "IN" });
  const [kycFields, setKYCFields] = useState([]);

  useEffect(() => {
    setKYCFields(countryKYCFields[country?.label] || []);
  }, [country]);

  const navigate = useNavigate();

  const profileref = useRef(null);
  const drugref = useRef(null);
  const explosiveref = useRef(null);
  const prohibitref = useRef(null);

  const handleFileChange = (event, setname, b_ref) => {
    const file = event.target.files[0];
    if (
      (file && file.type === "image/webp") ||
      (file && file.type === "image/jpeg") ||
      (file && file.type === "image/png") ||
      (file && file.type === "image/jpg")
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 2000000) {
          setname("");
          b_ref.current.value = null;
          toast.error(
            `The file size is too large. Please select a file under 1mb. ${file.size}`
          );
        } else {
          setname((prev) => {

            return file;
          });
        }
      };
    } else {
      setname("");
      b_ref.current.value = null;
      toast.error("Please select a valid WebP/jpeg/png image format.");
    }
  };

  const submitDocument = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");

    const formData = new FormData();
    formData.append("user_id", user.id);
    formData.append("profile", profile);
    formData.append("gst", gst);
    formData.append("drug_licence", drug_licence);
    formData.append("explosive_licence", explosive_licence);
    formData.append("prohibition", prohibition);

    const DynamicFormData = new FormData(e.target);

    kycFields.forEach((el, index) => {
      const file = DynamicFormData.get(el);
      formData.append(el, file);
    });

    const response = await fetch(`${baseUrl}/complete/documents`, {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");

    navigate("/account");
  };

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/profile/${user.id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);

    if (result.data.complete) {
      navigate("/account");
    }

    if (result.data.user.vendor) {
      const getVendor = result.data.user.vendor;
      setcountry({ label: getVendor?.country, value: getVendor?.country_code });
    }
  };

  useEffect(() => {
    syncProfile();
  }, []);

  return (
    <>
      <NavbarAuth />
      <section className="profile_page">
        <h1>User Profile Setup</h1>
        <p className="text-center">
          Complete your profile setup by uploading relevant documents and a user
          photo. Unlock the full potential of your presence on Rasayan Kart.
        </p>

        <ul className="summary step2 step3">
          <li>Company Details</li>
          <li>Documentation</li>
        </ul>
        <form onSubmit={submitDocument}>
          <div className="doc-type mb-4">
            <div className="name">
              <h6>Drug License Document</h6>
              <p className="desc">
                <span>*</span> All files should be in jpeg or png format, with a
                maximum file size of 2MB
              </p>
            </div>
            <div className="btn">
              <label className="uplaod" htmlFor="drug">
                <input
                  type="file"
                  required
                  accept="image/*"
                  ref={drugref}
                  onChange={(e) =>
                    handleFileChange(e, setdrug_licence, drugref)
                  }
                  className="text-dark bg-white border-white"
                  name="drug"
                  id="drug"
                />
                Upload File <FileUpload />
              </label>
            </div>
          </div>
          <div className="doc-type mb-4">
            <div className="name">
              <h6>Prohibition Excise Document</h6>
              <p className="desc">
                <span>*</span> All files should be in jpeg or png format, with a
                maximum file size of 2MB
              </p>
            </div>
            <div className="btn">
              <label className="uplaod" htmlFor="prohibit">
                <input
                  type="file"
                  accept="image/*"
                  required
                  ref={prohibitref}
                  onChange={(e) =>
                    handleFileChange(e, setprohibition, prohibitref)
                  }
                  className="text-dark bg-white border-white"
                  name="prohibit"
                  id="prohibit"
                />
                Upload File <FileUpload />
              </label>
            </div>
          </div>
          <div className="doc-type mb-4">
            <div className="name">
              <h6>Explosive License Document</h6>
              <p className="desc">
                <span>*</span> All files should be in jpeg or png format, with a
                maximum file size of 2MB
              </p>
            </div>
            <div className="btn">
              <label className="uplaod" htmlFor="explosive">
                <input
                  type="file"
                  required
                  accept="image/*"
                  ref={explosiveref}
                  onChange={(e) =>
                    handleFileChange(e, setexplosive_licence, explosiveref)
                  }
                  className="text-dark bg-white border-white"
                  name="explosive"
                  id="explosive"
                />
                Upload File <FileUpload />
              </label>
            </div>
          </div>

          <div className="doc-type mb-4">
            <div className="name">
              <h6>User Photo</h6>
              <p className="desc">
                <span>*</span> All files should be in jpeg or png format, with a
                maximum file size of 2MB
              </p>
            </div>
            <div className="btn">
              <label className="uplaod" htmlFor="profile">
                <input
                  type="file"
                  accept="image/*"
                  required
                  ref={profileref}
                  onChange={(e) => handleFileChange(e, setprofile, profileref)}
                  className="text-dark bg-white border-white"
                  name="profile"
                  id="profile"
                />
                Upload File <FileUpload />
              </label>
            </div>
          </div>

          {kycFields.map((field, index) => (
            <div className="doc-type mb-4" key={index}>
              <div className="name">
                <h6>{field}</h6>
                <p className="desc">
                  <span>*</span> All files should be in jpeg or png format, with
                  a maximum file size of 2MB
                </p>
              </div>
              <div className="btn">
                <label className="uplaod" htmlFor={field}>
                  <input
                    type="file"
                    accept="image/*"
                    required
                    ref={profileref}
                    onChange={(e) =>
                      handleFileChange(e, setprofile, profileref)
                    }
                    className="text-dark bg-white border-white"
                    name={field}
                    id={field}
                  />
                  Upload File <FileUpload />
                </label>
              </div>
            </div>
          ))}

          <div
            className="form-control"
            style={{ margin: "0 0 auto auto", width: "50%" }}
          >
            <button className="cancel" type="reset">
              Reset
            </button>
            <button className="complete" type="submit">
              Complete My Profile
            </button>
          </div>
        </form>
      </section>

      <Footer />
    </>
  );
};

export default ProfileStep3;
