import "./productdetails.css";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import SearchIcon from "@mui/icons-material/Search";
import StarIcon from "@mui/icons-material/Star";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Link, useParams, useSearchParams } from "react-router-dom";
//
import * as React from "react";
import Pd from "../Components/Pd/Pd";
import Slider from "../Components/Slider/Slider";

import Cart from "../../../assets/Bag_fill.png";
import Img from "../../../assets/product.png";
import Img2 from "../../../assets/product2.png";
import Imginfo from "../../../assets/Information.svg";
import Footer from "../../landing_page/Footer";
import ProductDesc from "../Components/ProductDesc/ProductDesc";
import { useState } from "react";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SortIcon from "@mui/icons-material/Sort";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Checkbox } from "@mui/material";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";

function ProductDetailsSeller() {
  const [size, setAge] = React.useState("");
  const [data, setData] = useState({});
  const [related, setRelated] = useState(false);

  const { slug, seller } = useParams();

  const fetchApi = async () => {
    const response = await fetch(`${baseUrl}/product/${slug}`, {
      headers: {
        Accept: "application/json",
      },
    });
    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }
    setData(() => result.data.data);
    setRelated(() => result.data.related);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const [value, setValue] = React.useState(0);
  function Add() {
    setValue(value + 1);
  }
  function Sub() {
    setValue(value - 1);
  }
  const [Cost, setCost] = useState(0);

  const getInitialState = () => {
    const val = "0";
    return val;
  };
  const getInitialStateN = () => {
    const valN = "0";
    return valN;
  };

  const [val, setVal] = useState(getInitialState);
  const [valN, setValN] = useState(getInitialStateN);

  const hC = (e) => {
    setVal(e.target.value);
  };
  const hCN = (e) => {
    setValN(e.target.value);
  };

  useEffect((useEffect) => {
    $("#a25").click(function () {
      $(".tab1").show();
      $(".tab2").hide();
      $(".tab3").hide();
      $(".dfltCheck").removeClass("dfltCheck");
    });
    $("#a50").click(function () {
      $(".tab1").hide();
      $(".tab2").show();
      $(".tab3").hide();
      $(".dfltCheck").removeClass("dfltCheck");
    });
    $("#a75").click(function () {
      $(".tab1").hide();
      $(".tab2").hide();
      $(".tab3").show();
      $(".dfltCheck").removeClass("dfltCheck");
    });
  }, []);

  const [isShown, setIsShown] = useState(false);

  const [count, setCount] = useState(1);
  const maxGuests = 99;
  const minGuests = 1;

  const handleCount = (e) => {
    if (e > maxGuests) {
      setCount(e);
    } else if (e < minGuests) {
      setCount(minGuests);
    } else setCount(e);
  };

  const decrementCount = () => {
    if (count > minGuests) setCount(count - 1);
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  useEffect(() => {
    fetchApi();
    const body = document.querySelector("#root");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  }, [slug, seller]);

  return (
    <>
      <NavbarAuth />
      <section className="product-details-container">
        <div className="pd-divider">
          <div className="pd-left">
            <div>
              <Pd
                base_image={data.url}
                additional={data.additional_images || []}
              />
            </div>
          </div>
          <div className="pd-right">
            <div className="pd-right-details">
              <div className="product-name text-capitalize">{data?.name}</div>

              <div className="product-rate-avail">
                <div
                  className={`product-availibility ${data.stock ? "in" : ""}`}
                >
                  {data.stock ? "In Stock" : "Stock out"}
                </div>
                {/* <div className="product-rating">
                  <div className="rating-heading">Ratings</div>
                  <div className="stars-div">
                    <div className="ratings-stars">
                      <StarIcon className="start-b" />
                      <StarIcon className="start-b" />
                      <StarIcon className="start-b" />
                      <StarIcon className="start-b" />
                      <StarIcon className="start-g" />
                    </div>
                    <div className="ratings-numbers">140,52</div>
                  </div>
                </div> */}
              </div>

              <div className="product-des">
                <div className="uni-heading">Description</div>
                <p className="">{data?.description}</p>
              </div>

              <div className="product-qty-pincode">
                <div>
                  <div className="pack">Pack Size</div>

                  <div className="product-size">
                    <div
                      className="tab1"
                      style={{
                        clear: "both",
                        float: "left",
                        marginTop: "20px",
                      }}
                    >
                      <select
                        className="SelectMl"
                        defaultValue={data.unit}
                        style={{
                          height: "40px",
                          width: "120px",
                          paddingLeft: "12px",
                          borderRadius: "5px",
                          background: "#EFEFEF",
                          border: "none",
                        }}
                      >
                        <option value={data.unit} selected>
                          {" "}
                          {data.unit}{" "}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="product-qty">
                  <div className="uni-heading">Quantity</div>
                  <div className="product-qty-input">
                    <button onClick={() => decrementCount()}>
                      <RemoveIcon />
                    </button>
                    {/* <div className="show-qty">{value < 0 ? 0 : value}</div> */}
                    <input
                      className="show-qty"
                      onChange={(e) => {
                        handleCount(Number(e.target.value));
                      }}
                      type="number"
                      value={count}
                    />
                    <button onClick={() => incrementCount()}>
                      <AddIcon />
                    </button>
                  </div>
                </div>
              </div>

              {/* table-cNoScroll */}
              <div style={{ position: "relative" }}>
                <div className="table-c ">
                  <table className="seller-price-table pdt">
                    <tr className="tablerow-head">
                      <th className="table-head">Seller</th>
                      <th className="table-head">Membership</th>
                      <th
                        onMouseEnter={() => setIsShown(true)}
                        onMouseLeave={() => setIsShown(false)}
                        className="table-head infoic"
                      >
                        Price <img src={Imginfo} />
                      </th>
                    </tr>
                    {data.catalog &&
                      data.catalog.products.map((item, index) => {
                        return (
                          <tr className="tablerow-data">
                            <td style={{ textAlign: "center" }}>
                              <Link to={`/seller/${item.vendor.id}`}>
                                {item.vendor?.vendor.company}
                              </Link>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <span className={item.vendor.member?.membership}>
                                {item.vendor.member?.membership}
                              </span>
                            </td>
                            <td
                              className="table-data"
                              style={{
                                textAlign: "center",
                                textTransform: "capitalize",
                              }}
                            >
                              ₹{item.max_price} / {item.unit}
                            </td>

                            <td
                              className="table-data"
                              style={{ textAlign: "center" }}
                            >
                              <Link to={`/product/${item.id}/seller`}>
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>

                {isShown && (
                  <div
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                    className="infoic-box"
                  >
                    <h5>Information</h5>
                    <h4>Exfactory Cost</h4>
                    <p>
                      The actual price will vary and depends upon multiple
                      factors including the volume purchased and the market
                      price of the day. The actual price will be calculated on
                      the shipping page
                    </p>
                    <h4>Landed Cost</h4>
                    <p>
                      The actual Landed cost will vary and depends upon the
                      volume and the logistics partner selected. The actual
                      landed price will be calculated on the shipping page
                    </p>
                  </div>
                )}
              </div>

              <div className="price-desc">
                <div className="price-heading">Price</div>
                <div className="ite-price-container">
                  {/* <span className="price old-price">₹60</span> */}
                  <span className="price new-price">₹{data.max_price}</span>
                  {/* <span className="price-span">Per Piece</span> */}
                </div>
                {/* <div className="discount-percentage">(10% Off)</div> */}
                <div className="price-btn-container df aic">
                  <Link
                    to={`/checkout?_id=${data.id}&qty=${count}`}
                    className="price-btn"
                  >
                    Buy Now
                  </Link>
                  <Link
                    to={`/rfqstep1?product=${data?.catalog?.id}`}
                    className="price-btn"
                    style={{ marginRight: "0px" }}
                  >
                    RFQ
                  </Link>
                </div>
              </div>

              {/*<div className="logistics-table-container">
                <table className="logistics-table">
                  <thead>
                    <tr className="logistics-headrow">
                      <th className="logistics-head"></th>
                      <th className="logistics-head">Logistics Provider</th>
                      <th className="logistics-head">Logistics Cost(INR)</th>
                      <th className="logistics-head">Delivery TAT(Day)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="logistics-datarow">
                      <td className="logistics-data log-inpu">
                        <input type="checkbox" />
                      </td>
                      <td className="logistics-data ">Logistics Partner</td>
                      <td className="logistics-data ">12000</td>
                      <td className="logistics-data ">2-4</td>
                    </tr>
                    <tr className="logistics-datarow">
                      <td className="logistics-data log-inpu">
                        <input type="checkbox" />
                      </td>
                      <td className="logistics-data ">Logistics Partner</td>
                      <td className="logistics-data ">12000</td>
                      <td className="logistics-data ">2-4</td>
                    </tr>
                  </tbody>
                </table>
                </div>*/}
            </div>
          </div>
        </div>
      </section>
      <section>
        <ProductDesc about={data.vendor} documents={data.documents || []} />
      </section>
      {related && (
        <section>
          <h2 className="h2-heading">Related Products</h2>
          <Slider data={related} />
        </section>
      )}
      <Footer />
    </>
  );
}

export default ProductDetailsSeller;
