import "./buyers.css";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import UserPic from "../../../assets/users.png";
import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { useEffect, useState } from "react";
import Moment from "react-moment";
import Greeting from "../../../component/Greeting";

function Users({name, id, pic, usertype, created_at}) {

  return (
    <div className="usercard">
      <div className="usercard-img mt-4">
        <img
          src={pic}
          alt={name}
          style={{ maxWidth: "100px" }}
          onError={(e) => {
            e.target.src = "/no-image.png";
          }}
        />
      </div>
      <div className="usercard-name text-capitalize">
        {name}
      </div>
      <div className="usercard-date text-nowrap">
        Created on:{" "}
        <span>
          <Moment format="ll">{created_at}</Moment>
        </span>
      </div>
      <Link to={`/profile/${id}/show`}>View Profile</Link>
      <div className="usertype">{usertype === "Admin" ? "Admin" : ""}</div>
    </div>
  );
}

export default function Buyersusers(params) {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/profile/${user.id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    if (!result.data.complete) {
      toast.dismiss(toastID);
      navigate("/complete");
    }


    setUser((prev) => {
      return {
        id: result.data.user.id,
        name: result.data.user.name,
        pic: result.data.user.doc[0]?.url,
        created_at: result?.data?.user?.created_at,
        type: "admin",
      };
    });

    toast.dismiss(toastID);
  };

  useEffect(() => {
    syncProfile();

    return () => toast.dismiss();
  }, []);

  return (
    <section className="buyers-section">
      <NavbarAuth />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={1} />
        </div>
        <div className="bsd-right">
        <div className="px-3 pt-3">
            <Greeting />
          </div>

          <div className="usercard-container">
            {user.type && <Users {...user} usertype="Vendor" />}
          </div>
          <div className="btn-con bottom-btn">
            <Link to="/bpf" className="conbtn-g">
              Add Sub Account
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
