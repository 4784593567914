import "./buyers.css";
import Navbar from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import CountrySelecter from "../Components/CountrySelecter/CountrySelecter";
import countryKYCFields from "../../../utils/kyc";

function Input(props) {
  return (
    <div className="df aic jcb new-input-c">
      <label htmlFor={props.id}>{props.label}</label>
      <input type="text" name={props.id} id={props.id} />
    </div>
  );
}

export default function BuyersProfileEdit() {
  const [company, setCompany] = useState("");
  const [gst, setgst] = useState("");
  const [address2, setaddress2] = useState("");
  const [address, setaddress] = useState("");
  const [zip, setzip] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState({ label: "India", value: "IN" });
  const [drug_licence, setdrug_licence] = useState("");
  const [udyam_aadhar, setudyam_aadhar] = useState("");
  const [explosive_licence, setexplosive_licence] = useState("");
  const [prohibition, setprohibition] = useState("");

  const [kycFields, setKYCFields] = useState([]);

  const navigate = useNavigate();

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/profile/${user.id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);

    // if (result.data.complete) {
    //   navigate("/account");
    // }

    if (result.data.user.vendor) {
      const getVendor = result.data.user.vendor;
      setCompany(getVendor?.company);
      setgst(getVendor?.gst);
      setaddress2(getVendor?.address2);
      setaddress(getVendor?.address);
      setzip(getVendor?.zip);
      setcity(getVendor?.city);
      setstate(getVendor?.state);
      setdrug_licence(getVendor?.drug_licence);
      setudyam_aadhar(getVendor?.udyam_aadhar);
      setexplosive_licence(getVendor?.explosive_licence);
      setprohibition(getVendor?.prohibition);

      setcountry({ label: getVendor?.country, value: getVendor?.country_code });
    }
  };

  const profile = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");

    const formData = new FormData(e.target);

    const fields = kycFields.map((el, index) => {
      return { [el]: formData.get(el) };
    });

    const response = await fetch(`${baseUrl}/complete`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: user.id,
        company,
        gst,
        city,
        state,
        country: country.label,
        country_code: country.value,
        zip,
        address,
        address2,
        udyam_aadhar,
        drug_licence,
        prohibition,
        explosive_licence,
        fields,
      }),
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");

    syncProfile();
  };

  useEffect(() => {
    syncProfile();
  }, []);

  return (
    <section className="buyers-section">
      <Navbar />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={1} />
        </div>
        <div className="bsd-right">
          <div className="buyers-profile-box">
            <strong className="grey SubAcntHedng">Edit Profile</strong>
            <form onSubmit={profile}>
              <div className="d-flex">
                <div className="form-control mb-4">
                  <label className="required">Company Name</label>
                  <input
                    required
                    className="form-control"
                    value={company}
                    onInput={(e) => setCompany(e.target.value)}
                    type="text"
                  />
                </div>
                <div className="form-control mb-4">
                  <label className="required">GST Number</label>
                  <input
                    required
                    className="form-control"
                    value={gst}
                    onInput={(e) => setgst(e.target.value)}
                    type="text"
                  />
                </div>
              </div>

              <div className="d-flex">
                <div className="form-control country mb-4">
                  <label className="required">Country</label>
                  <CountrySelecter country={country} setCountry={setcountry} />
                </div>

                <div className="form-control mb-4">
                  <label className="required">Zip Code</label>
                  <input
                    required
                    value={zip}
                    className="form-control"
                    onInput={(e) => setzip(e.target.value)}
                    type="number"
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-control mb-4">
                  <label className="required">State </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={state}
                    onInput={(e) => setstate(e.target.value)}
                  />
                </div>

                <div className="form-control mb-4">
                  <label className="required">City</label>
                  <input
                    required
                    value={city}
                    className="form-control"
                    onInput={(e) => setcity(e.target.value)}
                    type="text"
                  />
                </div>
              </div>
              <div className="form-control mb-4">
                <label className="required">Business Address Line 1</label>
                <input
                  required
                  value={address}
                  className="form-control"
                  onInput={(e) => setaddress(e.target.value)}
                  type="text"
                />
              </div>
              <div className="form-control mb-4">
                <label className="required">Business Address Line 2</label>
                <input
                  value={address2}
                  className="form-control"
                  onInput={(e) => setaddress2(e.target.value)}
                  type="text"
                />
              </div>
              <div className="d-flex">
                <div className="form-control mb-4">
                  <label className="required">Drug License Number</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={drug_licence}
                    onInput={(e) => setdrug_licence(e.target.value)}
                  />
                </div>
                <div className="form-control mb-4">
                  <label className="required">Udyam Aadhaar Number</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={udyam_aadhar}
                    onInput={(e) => setudyam_aadhar(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className="form-control mb-4">
                  <label className="required">Explosive License Number </label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={explosive_licence}
                    onInput={(e) => setexplosive_licence(e.target.value)}
                  />
                </div>
                <div className="form-control mb-4">
                  <label className="required">Prohibition Excise</label>
                  <input
                    required
                    type="text"
                    className="form-control"
                    value={prohibition}
                    onInput={(e) => setprohibition(e.target.value)}
                  />
                </div>
              </div>

              {/* <div className="form-control mb-4">
            <label className="required">Udhayam Aadhaar Number</label>
            <input
              required
              type="text"
              value={aadhaar}
              onInput={(e) => setaadhaar(e.target.value)}
              name="aadhaar"
            />
          </div> */}

              <div className="d-flex">
                {kycFields.map((field, index) => (
                  <div className="mb-4" key={index}>
                    <label className="required" htmlFor={field}>
                      {field}:
                    </label>
                    <input
                      type={"text"}
                      className="form-control"
                      required
                      id={field}
                      name={field}
                    />
                  </div>
                ))}
              </div>

              <div
                className="form-control"
                style={{ margin: "0 0 auto auto", width: "50%" }}
              >
                <button className="continue" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
