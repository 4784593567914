import "./buyers.css";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import CreateIcon from "@mui/icons-material/Create";
import Pdf from "../Components/Pdf/Pdf";
import Newpdf from "../Components/Pdf/Newpdf";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { useEffect, useState } from "react";
import { baseUrl } from "../../../utils/baseUrl";
import { useNavigate } from "react-router-dom";
import Greeting from "../../../component/Greeting";

function BuyersDocuments() {
  const [doc, setdoc] = useState([]);

  const navigate = useNavigate();

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const response = await fetch(`${baseUrl}/profile/${user.id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    if (!result.data.complete) {
      toast.dismiss(toastID);
      navigate("/complete");
    }

    setdoc((prev) => result.data.user.doc);
    toast.dismiss(toastID);
  };

  useEffect(() => {
    syncProfile();
  }, []);
  return (
    <section className="buyers-section">
      <NavbarAuth />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={5} />
        </div>
        <div className="bsd-right">
        <Greeting />
          <div className="buyers-profile-box bd-sec documentCart">
            {/* <div className="edit-circle"><CreateIcon /></div> */}
            
            {
                doc && doc.map((item, index) => {
                    return <Newpdf name={item.label} size="2 mb" link={item.url} url={item.url} />
                })
            }
            
          </div>
        </div>
      </div>
    </section>
  );
}

export default BuyersDocuments;
