import React from 'react'
import './mob.css';
import { Link } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MobInputs from './MobInput';
import Navbar from '../../landing_page/Navbar';
import Footer from '../../landing_page/Footer';


function MobDet2(props) {
    return (
        <>
        <Navbar />
        
        <div className="moblogin-container">
            <Link to={'/mdet1'} className="back-arrow"><KeyboardBackspaceIcon /></Link>
            <div className="mob-heading">Enter Details To sign up</div>

            <div className="progess-line">
                <div className="forget-b">Fill up the form</div>
                <div className="progress-container"><span className="progess p2"></span></div>
            </div>

            <form>
                <div className="mob-input-container">
                    <label htmlFor='user-txtarea'>Address</label>
                    <textarea name='user-txtarea' id='user-txtarea' required />
                </div>
                <MobInputs title='Secondary name(optional)' type='text' name='new_user_sn' />
                <MobInputs title='Secondary contact(optional)' type='number' name='new_user_sc' />
                <MobInputs title='Pan Card' type='text' name='new_user_pan' />
                <MobInputs title='Tan Card' type='text' name='new_user_tan' />
                <MobInputs title='GST number' type='text' name='new_user_gst' />
                <MobInputs title='Udhayam Aadhat Number' type='text' name='new_user_uan' />
                <MobInputs title='Drug Liscense' type='text' name='new_user_dl' />
                <MobInputs title='Explosive Liscense Number' type='text' name='new_user_eln' />
                <MobInputs title='Prohibition Excise' type='text' name='new_user_pe' />

                <Link to={'/shop'} className='conbtn-g mob-btn'>Continue</Link>
            </form>

        </div>
        <Footer />
        </>
    )
}

export default MobDet2;