import React, { useEffect, useState } from "react";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { useNavigate } from "react-router-dom";
import CountrySelecter from "../Components/CountrySelecter/CountrySelecter";
import countryKYCFields from "../../../utils/kyc";

const ProfileStep2 = () => {
  const [company, setCompany] = useState("");
  const [gst, setgst] = useState("");
  const [address2, setaddress2] = useState("");
  const [address, setaddress] = useState("");
  const [zip, setzip] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState({ label: "India", value: "IN" });
  const [drug_licence, setdrug_licence] = useState("");
  const [udyam_aadhar, setudyam_aadhar] = useState("");
  const [explosive_licence, setexplosive_licence] = useState("");
  const [prohibition, setprohibition] = useState("");

  const [kycFields, setKYCFields] = useState([]);

  useEffect(() => {
    setKYCFields(countryKYCFields[country?.label] || []);
  }, [country]);

  const navigate = useNavigate();

  const profile = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");

    const formData = new FormData(e.target);

    const fields = kycFields.map((el, index) => {
      return { [el]: formData.get(el) };
    });

    const response = await fetch(`${baseUrl}/complete`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        user_id: user.id,
        company,
        gst,
        city,
        state,
        country: country.label,
        country_code: country.value,
        zip,
        address,
        address2,
        drug_licence,
        udyam_aadhar,
        prohibition,
        explosive_licence,
        fields,
      }),
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");

    navigate("/complete/documents");
  };

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/profile/${user.id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();


    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);

    if (result.data.complete) {
      navigate("/account");
    }

    if (result.data.user.vendor) {
      const getVendor = result.data.user.vendor;
      setCompany(getVendor?.company);
      setgst(getVendor?.gst);
      setaddress2(getVendor?.address2);
      setaddress(getVendor?.address);
      setzip(getVendor?.zip);
      setcity(getVendor?.city);
      setstate(getVendor?.state);
      setdrug_licence(getVendor?.drug_licence);
      setexplosive_licence(getVendor?.explosive_licence);
      setudyam_aadhar(getVendor?.udyam_aadhar);
      setprohibition(getVendor?.prohibition);

      setcountry({ label: getVendor?.country, value: getVendor?.country_code });
    }
  };

  const fetchzip = async (zipCode, cr) => {
    try {
      if (zipCode.length <= 3) {
        return false;
      }
      const response = await fetch(
        `https://api.zippopotam.us/${cr}/${zipCode}`
      );
      const data = await response.json();

      const state = data.places[0]["state"];

      if (!state) {
        setstate(state);
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    syncProfile();
  }, []);

  useEffect(() => {
    fetchzip(zip, country.value);
  }, [zip]);

  return (
    <>
      <NavbarAuth />
      <section className="profile_page">
        <h1>Company Details</h1>
        <p className="text-center">
          Elevate your presence in the chemical industry by providing your
          company details. Enhance credibility, expand your network on Rasayan
          Kart.
        </p>
        <ul className="summary step2">
          {/*<li>Basic Details</li>*/}
          <li>Company Details</li>
          <li>Documentation</li>
        </ul>
        <form onSubmit={profile}>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label className="required">Company Name</label>
              <input
                required
                value={company}
                onInput={(e) => setCompany(e.target.value)}
                type="text"
              />
            </div>

            <div className="form-control mb-4">
              <label className="required">GST Number</label>
              <input
                required
                value={gst}
                onInput={(e) => setgst(e.target.value)}
                type="text"
              />
            </div>
          </div>

          <div className="d-flex">
            <div className="form-control country mb-4">
              <label className="required">Country</label>

              {/* <label className="required">Country</label>
              <input
                required
                type="text"
                name="country"
                value={country}
                onInput={(e) => setcountry(e.target.value)}
              /> */}
              <CountrySelecter country={country} setCountry={setcountry} />
            </div>

            <div className="form-control mb-4">
              <label className="required">Zip Code</label>
              <input
                required
                value={zip}
                onInput={(e) => setzip(e.target.value)}
                type="number"
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label className="required">State </label>
              <input
                required
                type="text"
                value={state}
                onInput={(e) => setstate(e.target.value)}
              />
            </div>

            <div className="form-control mb-4">
              <label className="required">City</label>
              <input
                required
                value={city}
                onInput={(e) => setcity(e.target.value)}
                type="text"
              />
            </div>
          </div>
          <div className="form-control mb-4">
            <label className="required">Business Address Line 1</label>
            <input
              required
              value={address}
              onInput={(e) => setaddress(e.target.value)}
              type="text"
            />
          </div>
          <div className="form-control mb-4">
            <label className="required">Business Address Line 2</label>
            <input
              value={address2}
              onInput={(e) => setaddress2(e.target.value)}
              type="text"
            />
          </div>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label className="">Drug License Number</label>
              <input
                type="text"
                value={drug_licence}
                onInput={(e) => setdrug_licence(e.target.value)}
              />
            </div>
            <div className="form-control mb-4">
              <label className="">Udyam Aadhaar Number</label>
              <input
                type="text"
                value={udyam_aadhar}
                onInput={(e) => setudyam_aadhar(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="form-control mb-4">
              <label className="">Explosive License Number </label>
              <input
                type="text"
                value={explosive_licence}
                onInput={(e) => setexplosive_licence(e.target.value)}
              />
            </div>
            <div className="form-control mb-4">
              <label className="required">Prohibition Excise</label>
              <input
                required
                type="text"
                value={prohibition}
                onInput={(e) => setprohibition(e.target.value)}
              />
            </div>
          </div>

          {/* <div className="form-control mb-4">
            <label className="required">Udhayam Aadhaar Number</label>
            <input
              required
              type="text"
              value={aadhaar}
              onInput={(e) => setaadhaar(e.target.value)}
              name="aadhaar"
            />
          </div> */}

          <div className="d-flex">
            {kycFields.map((field, index) => (
              <div className="form-control mb-4" key={index}>
                <label className="required" htmlFor={field}>
                  {field}:
                </label>
                <input type={"text"} required id={field} name={field} />
              </div>
            ))}
          </div>

          <div
            className="form-control"
            style={{ margin: "0 0 auto auto", width: "50%" }}
          >
            <button className="cancel" type="reset">
              reset
            </button>
            <button className="continue" type="submit">
              Continue
            </button>
          </div>
        </form>
      </section>

      <Footer />
    </>
  );
};

export default ProfileStep2;
