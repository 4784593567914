const countryKYCFields = {
  India: ["Aadhaar Card", "PAN Card"],
  China: ["ID Card"],
  'United Kingdom': ["National Insurance Number"],
  'United States': ["Social Security Number", "Driver's License"],
  Bangladesh: ["National ID Card"],
  Austria: ["Reisepass"],
  Belgium: ["Identity Card"],
  Bulgaria: ["Personal Card"],
  Croatia: ["Identity Card"],
  Cyprus: ["Identity Card"],
  "Czech Republic": ["Citizen Card"],
  Denmark: ["CPR Number"],
  Estonia: ["ID Card"],
  Finland: ["ID Card"],
  France: ["National Identity Card"],
  Germany: ["Personalausweis"],
  Greece: ["Identity Card"],
  Hungary: ["Personal Identification Card"],
  Ireland: ["Public Services Card"],
  Italy: ["Identity Card"],
  Latvia: ["Identity Card"],
  Lithuania: ["Identity Card"],
  Luxembourg: ["Identity Card"],
  Malta: ["Identity Card"],
  Netherlands: ["Identity Card"],
  Poland: ["Identity Card"],
  Portugal: ["Citizen Card"],
  Romania: ["Identity Card"],
  Slovakia: ["Identity Card"],
  Slovenia: ["Identity Card"],
  Spain: ["National Identity Card"],
  Sweden: ["Identity Card"],
};

export default countryKYCFields;
