import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import { RemoveRedEye } from "@mui/icons-material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Slider from "./Slider";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthContext";
import { useContext } from "react";
import { useState } from "react";
import { baseUrl } from "../../../utils/baseUrl";
import { toasterUpdate } from "../../../utils/toasterUpdate";

const Account = () => {
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const { auth, setAuth } = useContext(AuthContext);

  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/register`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ name, phone, email, password }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, result.message, "success");

    navigate("/login");
  };

  const token = localStorage.getItem("token_web");

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Remove non-digit characters
    const newValue = value.replace(/\D/g, "");
    // Limit to maximum 10 digits
    const limitedValue = newValue.slice(0, 10);
    
    setphone(limitedValue);
  };

  useEffect(() => {
    if (token) {
      navigate("/account");
    }
  }, []);
  return (
    <>
      <section className="welcome_page">
        <Navbar />
        <div className="container">
          <div className="box-display">
            <form className="leftwel" onSubmit={register}>
              <h1>Create An Account </h1>
              <p>
                Join the Rasayan Kart community today and unlock limitless
                opportunities. Sign up now to connect, buy and sell chemicals
                hassle-free.
              </p>
              <div className="form-data d-flex">
                <div className="form-control mb-4">
                  <label>Full Name *</label>
                  <input
                    type="text"
                    name="name"
                    onInput={(e) => setname(e.target.value)}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="form-control mb-4">
                <label>Phone *</label>
                <input
                  type="tel"
                  pattern="[0-9]{10}"
                  value={phone}
                  onChange={handlePhoneChange}
                  name="phone"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-control mb-4">
                <label>Email ID *</label>
                <input
                  type="email"
                  onInput={(e) => setemail(e.target.value)}
                  name="email"
                  className="form-control"
                  required
                />
              </div>
              <div className="form-control">
                <label>Password *</label>
                <input
                  type="password"
                  name="Password"
                  onInput={(e) => setpassword(e.target.value)}
                  className="form-control"
                  required
                />
              </div>
              <button className="account" type="submit">
                Continue
              </button>
              {/* <Link to="" className="sign">
                Or Sign Up with
              </Link>
              <button className="google">Sign Up with Google</button> */}
              <Link to="/login" className="dont">
                Already Have An Account ?
              </Link>
            </form>
            <div className="rightwel">
              <h1>Reduce Cost. Increase Profits</h1>
              <p>
                Your one-stop solution for buying and selling chemicals online.
                Explore our vast selection of chemicals, empowering your
                business growth with every transaction.
              </p>
              <Slider />
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Account;
