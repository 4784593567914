import React from 'react'
import './mob.css';
import { Link } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import Navbar from '../../landing_page/Navbar';
import Footer from '../../landing_page/Footer';


function MobNumber(props) {

    const [value, setValue] = useState()

    return (
        <>
        <Navbar />
        <div className="moblogin-container">
            <Link to={'/'} className="back-arrow"><KeyboardBackspaceIcon /></Link>
            <div className="mob-heading">Enter your mobile number</div>

            <form>
                <div className="mob-input-container">
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={value}
                        onChange={setValue} />
                </div>
                <div style={{height:"30px"}} className="df aic jcb">
                    <div className="forget-b">Trouble in signin?</div>
                    <div className="forget-p">Help</div>
                </div>

                <div  className='forget-b uno-div'>By continuing ,you agree to our <br /> <div className="forget-p">terms & conditions</div></div>

                <div className="df fdc aic jcc">
                    <div className="option-heading">Or Sign in with</div>
                    <div className='df aic jcc'>
                    <div className="option"><GoogleIcon/></div>
                    <div className="option"><FacebookRoundedIcon/></div>
                    </div>
                </div>
                
                <Link to={'/motp'} className='conbtn-g mob-btn'>Continue</Link>
                <p className="black-p">Already has account? <Link to='/mlogin' className='blue-p'>Login</Link></p>
            </form>

        </div>
        <Footer />
        </>
    )
}

export default MobNumber;