import "./buyers.css";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import Banner from "../../../assets/bban.png";
import CreateIcon from "@mui/icons-material/Create";
import ApartmentIcon from "@mui/icons-material/Apartment";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { useEffect, useState } from "react";

function DetailsList({ array }) {
  return array.map((array, index) => (
    <div key={index} className="greybox df aic">
      {array}
    </div>
  ));
}

export default function BuyersProfile() {
  const [user, setUser] = useState(false);
  const navigate = useNavigate();

  const { id } = useParams();

  const syncProfile = async (e) => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/profile/${id}/vendor`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    if (!result.data.complete) {
      toast.dismiss(toastID);
      navigate("/complete");
    }

    setUser((prev) => result.data.user);
    toast.dismiss(toastID);
  };

  useEffect(() => {
    syncProfile();
  }, []);

  return (
    <section className="buyers-section">
      <NavbarAuth />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={1} />
        </div>

        <div className="bsd-right">
          {user && (
            <div className="buyers-profile-box">
              <div className="buyers-profile-banner">
                <img
                  src={Banner}
                  alt={user.vendor?.company}
                  onError={(e) => {
                    e.target.src = Banner;
                  }}
                />
              </div>
              <div className="buyers-dp">
                <img
                  src={user.doc[0] ? user.doc[0].url : Banner}
                  alt={user.vendor?.company}
                  onError={(e) => {
                    e.target.src = "/no-image.png";
                  }}
                  className="img-fluid"
                />
              </div>
              <div className="edb">
                <Link to="/buyeredit" className="conbtn-g">
                  Edit Details
                </Link>
              </div>
              <div className="buyers-name text-capitalize">{user?.name}</div>
              {/* <div className="greybox df aic"><ApartmentIcon className="greybox-i" /> Umiya Agro Industries</div> */}

              <div className="greybox df aic">Email ID : {user?.email}</div>
              <div className="greybox df aic">Contact : {user?.phone}</div>
              <div className="greybox df aic">
                Company : {user?.vendor.company}
              </div>
              <div className="greybox df aic">GST No. : {user?.vendor.gst}</div>
              <div className="greybox df aic">
                Explosive Licence No. : {user?.vendor.explosive_licence}
              </div>
              <div className="greybox df aic">
                Address : {user?.vendor.address}
              </div>

              <div className="greybox df aic">
                Drug Licence No. : {user?.vendor.drug_licence}
              </div>
              <div className="greybox df aic">
                Udyam Aadhaar number. : {user?.vendor.udyam_aadhar}
              </div>
              <div className="greybox df aic">
                Prohibition Excise : {user?.vendor.prohibition}
              </div>

              {user?.kyc.map((el, index) => {
                return (
                  <div className="greybox df aic">
                    {el.label?.replace("_", "")} : {el.value}
                  </div>
                );
              })}
              {/* <div className="btn-con">
                <Link to="/bpf" className="conbtn-g">
                  Add Sub Account
                </Link>
              </div> */}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
