import React, { useState, useEffect } from "react";

import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import "./buyers.css";
import { useParams } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import "jquery-ui-dist/jquery-ui";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { useRef } from "react";
import { Accordion } from "react-bootstrap";
import Moment from "react-moment";

function SupportDetail() {
  const [data, setData] = useState({});

  const { id } = useParams();

  const [enable, setEnable] = useState(true);

  const [message, setMessage] = useState("");
  const [attach, setAttach] = useState("");
  const replyRef = useRef(null);
  const attachRef = useRef(null);

  const token = localStorage.getItem("token_web");

  const fetchApi = async () => {
    const toastID = toast.loading("Processing...");
    replyRef.current.value = "";
    attachRef.current.value = "";
    const response = await fetch(`${baseUrl}/tickets/${id}/show`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    setData(() => result.data);
    setEnable(result.data.status == "resolved");
  };

  const respondApi = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("message", message);
    formData.append("attach", attach);
    const toastID = toast.loading("Sending...");

    setEnable(true);

    const response = await fetch(`${baseUrl}/tickets/${id}/respond`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();

    if (result.error) {
      setEnable(false);
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    fetchApi();
  };

  const resolveApi = async () => {
    const tokenID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/tickets/${id}/resolve`, {
      method: "post",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(tokenID, result.message, "error");
      return;
    }

    toasterUpdate(tokenID, result.message, "success");
    fetchApi();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpeg") ||
      (file && file.type === "image/png") ||
      (file && file.type === "image/webp") ||
      (file && file.type === "image/jpg") ||
      (file && file.type === "application/pdf")
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 400000) {
          setAttach("");
          attachRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 400kb."
          );
        } else {
          setAttach(file);
        }
      };
    } else {
      setAttach("");
      attachRef.current.value = null;
      toast.error("Please select a valid image or pdf format.");
    }
  };

  useEffect(() => {
    fetchApi();
  }, []);

  return (
    <section className="buyers-section">
      <NavbarAuth />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={10} />
        </div>
        <div className="bsd-right bsd-right8247">
          <div className="row support-detail-container">
            <div className="col-md-4">
              <div className="form-group">
                <label>Ticket ID</label>
                <p>{data.ticket_id}</p>
              </div>
              <div className="form-group">
                <label>Opening Date</label>
                <p><Moment format="D-MMMM-Y hh:mm a">{data.created_at}</Moment></p>
              </div>
              <div className="form-group">
                <label>Closed Date</label>
                <p>{data.closed_date}</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <label>Company Name</label>
                <p>{data.vendor?.company}</p>
              </div>
              <div className="form-group">
                <label>Subject</label>
                <p>{data.subject}</p>
              </div>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-2">
              <div className="d-flex justify-content-between">
                <div>
                  <div class="dropdown">
                    <button
                      class="btn btn-primary btn-theme1 dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {data.status}
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <a className="dropdown-item" onClick={resolveApi}>
                          {data.status == "resolved" ? "Pending" : "Resolved"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <Accordion defaultActiveKey="0" flush className="mt-5">
              {data &&
                data.responds &&
                data.responds.map((item, index) => {
                  return (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div className="top">
                          <p>
                            <Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment>
                          </p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="bottom">
                          <p>
                            <b>{item.role == "admin" ? "Support" : "You"}</b>
                            {" : "}
                            {item.message}
                          </p>
                          {item.attach && (
                            <p>
                              <b>Document</b>
                              {" : "}
                              <a href={item.url} target="_blank">
                                Download
                              </a>
                            </p>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
            </Accordion>

            <form onSubmit={respondApi} className="support_details">
              <div className="form-group mb-4">
                <label style={{ width: "20%" }}>Reply</label>
                <textarea
                  ref={replyRef}
                  onInput={(e) => setMessage(e.target.value)}
                  rows={5}
                  cols={6}
                  placeholder="Type Here..."
                />
              </div>
              <div className="form-group">
                <label style={{ width: "20%" }}>Attach Document</label>
                <input
                  type="file"
                  ref={attachRef}
                  onChange={handleFileChange}
                  name="doc"
                  accept="image/*, application/pdf"
                />
              </div>

              <div className="buttons mt-4">
                <button type="submit" disabled={enable} className="resolve">
                  Reply
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SupportDetail;
