import React, { useState, useMemo } from "react";
import Select from "react-select";
import countryList from "react-select-country-list";
import { baseUrl } from "../../../../utils/baseUrl";

const CountrySelector = ({ country, setCountry }) => {
  const [option, setOption] = useState([]);

  const fetchApi = async () => {
    try {
      const response = await fetch(`${baseUrl}/countries`);
      const result = await response.json();
      return result.data;
    } catch (error) {
      return [];
    }
  };

  useMemo(async () => {
    const data = await fetchApi();
    setOption(data);
  }, []);

  const changeHandler = (value) => {
    setCountry(value);
  };

  return <Select options={option} value={country} onChange={changeHandler} />;
};

export default CountrySelector;
