import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import { Close, Edit } from "@mui/icons-material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { Box, Fade } from "@mui/material";
import { baseUrl } from "../../../utils/baseUrl";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { toast } from "react-toastify";
import Moment from "react-moment";

const RFQ = () => {
  const [data, setData] = React.useState({});

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  const { rfq_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/rfq/${id}/show`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    setData(result.data);
  };

  const closeRfq = async () => {
    const toastID = toast.loading("Closing...");
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/rfq/${id}/close`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    navigate("/pending");
  };

  React.useEffect(() => {
    fetchApi();

    return () => toast.dismiss();
  }, []);

  return (
    <>
      <section className="buyers-section">
        <NavbarAuth />
        <div className="buyers-section-div">
          <div className="bsd-left">
            {/* <BuyersSidebar n={7} /> */}
            <BuyersSidebar />
          </div>
          <div className="bsd-right rfq profile_page">
            <div className="form-inputs input-type">
              <div>
                <div className="inputs">
                  <label>RFQ Name</label>
                  <span className="text-capitalize">{data.label}</span>
                </div>
                <div className="inputs">
                  <label>RFQ Till Date</label>
                  <span>
                    <Moment format="D-MMMM-Y">{data.opentill}</Moment>
                  </span>
                </div>
              </div>
              <div>
                <div className="inputs">
                  <label>Buyer’s Name</label>
                  <span className="text-capitalize">
                    {data.vendor?.vendor.company}
                  </span>
                </div>
                <div className="inputs">
                  <label>Delivery Address</label>
                  <span>
                    {data.vendor?.vendor.address} {data.vendor?.vendor.city}{" "}
                    {data.vendor?.vendor.zip} {data.vendor?.vendor.country}
                  </span>
                </div>
              </div>
            </div>
            <div className="form-inputs" style={{ paddingTop: "40px" }}>
              <div className="inputs">
                <label>Product Order Type</label>
                <span className="text-capitalize">
                  {data.order_type?.replace("_", " ")}
                </span>
              </div>
              <div className="inputs">
                <label>Payment Type</label>
                <span className="text-capitalize">{data.payment_type}</span>
              </div>
              <div className="inputs">
                <label>Payment Details</label>
                <span className="text-capitalize">
                  {data.payment_type == "credit" ? "milestone" : "spot payment"}
                </span>
              </div>

              {data.order_based && (
                <>
                  <table className="table table-bordered w-50 mt-2">
                    <caption>Order Status Based Milestone</caption>
                    <tr>
                      <th>Label</th>
                      <th>Days</th>
                      <th>Amount</th>
                      <th>Payment Status</th>
                    </tr>

                    <tr>
                      <td>Placement</td>
                      <td>{data.order_based.placement_day}</td>
                      <td>{data.order_based.placement_amount}%</td>
                      <td className="text-capitalize">
                        {data.order_based.status}
                      </td>
                    </tr>
                    <tr>
                      <td>Fabrication</td>
                      <td>{data.order_based.fabrication_day}</td>
                      <td>{data.order_based.fabrication_amount}%</td>
                      <td className="text-capitalize">
                        {data.order_based.status}
                      </td>
                    </tr>
                    <tr>
                      <td>Dispatched</td>
                      <td>{data.order_based.dispatched_day}</td>
                      <td>{data.order_based.dispatched_amount}%</td>
                      <td className="text-capitalize">
                        {data.order_based.status}
                      </td>
                    </tr>
                    <tr>
                      <td>QC</td>
                      <td>{data.order_based.qc_day}</td>
                      <td>{data.order_based.qc_amount}%</td>
                      <td className="text-capitalize">
                        {data.order_based.status}
                      </td>
                    </tr>
                  </table>
                </>
              )}

              {data.cycle_based && data.cycle_based.length > 0 && (
                <>
                  <table className="table table-bordered w-50 mt-2">
                    <caption>Cycle Based Milestone</caption>
                    <tr>
                      <th>Day</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>

                    {data.cycle_based.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.days}</td>
                          <td>{item.amount}%</td>
                          <td className="text-capitalize">{item.status}</td>
                        </tr>
                      );
                    })}
                  </table>
                </>
              )}
            </div>

            <div
              className="pdt_Detail form-inputs"
              style={{ paddingTop: "40px" }}
            >
              <h4>Seller Responses</h4>

              <table className="table">
                <tr className="fw-bold bg-light">
                  <th className="py-2">Sr. No.</th>
                  <th className="py-2">Seller</th>
                  <th className="py-2">Product</th>
                  <th className="py-2">No. of Responses</th>
                  <th className="py-2">Status</th>
                  <th className="py-2">Action</th>
                </tr>

                {data &&
                  data.responds &&
                  data.responds.map((item, index) => (
                    <tr>
                      <td className="py-2">{index + 1}</td>
                      <td className="py-2 text-capitalize">
                        {item?.seller?.vendor?.company}
                      </td>
                      <td className="py-2 text-capitalize">
                        {item.product.name}
                      </td>
                      <td className="py-2 text-capitalize">
                        {item.responds_count || 0}
                      </td>
                      <td
                        className={` text-capitalize fw-bold ${
                          (item.status == "ongoing" || item.status == "accept")
                            ? "btn text-success"
                            : "btn text-danger"
                        }`}
                      >
                        {item.status}
                      </td>
                      <td className="py-2">
                        <Link to={`/rfq/${item.id}/seller`}>View</Link>
                      </td>
                    </tr>
                  ))}
              </table>
              {/* <h6
                className="text-capitalize"
                style={{ color: "#000", marginBottom: "30px" }}
              >
                {data.product?.name}{" "}
                <span className="text-capitalize">
                  {data.qty} {data.product?.unit}
                </span>
                <span className="text-capitalize">
                  {" "}
                  {data.product?.catalog.category_id?.name}
                </span>
              </h6>
              <div className="inputs">
                <label>Product Price </label>
                <span className="text-capitalize">
                  ₹{data.price} Per {data.product?.unit}
                </span>
              </div>
              <div className="inputs">
                <label>Product Quantity </label>
                <span className="text-capitalize">
                  {data.quantity} {data.product?.unit}
                </span>
              </div> */}
            </div>
            {/* <div className=" custom-file-upload">
              <label className="label">Additional Details</label>
              <p
                style={{
                  fontSize: "13px",
                  textAlign: "left",
                  color: "#7B7B7B",
                }}
                className="text-capitalize"
              >
                {data.note}
              </p>
            </div> */}
            <div
              className="text-end"
              style={{ margin: "50px 0 auto auto", width: "50%" }}
            >
              {/*<button className="cancel">Cancel</button>*/}
              <button className="continue" onClick={closeRfq}>
                Close RFQ
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* modal popup */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open} className="response-modal">
          <Box>
            <div className="body">
              <h3>Respond RFQ </h3>
              <div className="form-control mb-4">
                <label>Product Price</label>
                <input type="text" name="Price" />
              </div>
              <div className="form-control mb-4">
                <label>Product GST</label>
                <input type="text" name="GST" />
              </div>
              <div className="form-control mb-4">
                <label>Quantity</label>
                <input type="text" name="Quantity" />
              </div>
              <div className="form-control mb-4">
                <label>Landed Cost</label>
                <input type="text" name="Cost" />
              </div>
              <div className="form-control mb-4">
                <label>Additional Info</label>
                <textarea rows="3" type="text" name="Info" />
              </div>
              <div className="form-btn">
                <button className="cancel">Cancel</button>
                <button className="continue">Send</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default RFQ;
