import { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import Accordion from "react-bootstrap/Accordion";

// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
// import Modal from 'react-bootstrap/Modal';
// import { useState } from 'react';
import Typography from "@material-ui/core/Typography";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Box, Fade } from "@mui/material";
import { baseUrl } from "../../../utils/baseUrl";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Moment from "react-moment";
import formatter from "../../../utils/formatter";

const RfqSeller = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [enable, setEnable] = useState(true);

  const [price, setPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [doc, setDoc] = useState("");
  const [note, setNote] = useState("");

  const attachRef = useRef(null);

  const token = localStorage.getItem("token_web");
  const user = JSON.parse(localStorage.getItem("auth_user"));

  const navigate = useNavigate();
  const { id } = useParams();

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/rfq/${id}/seller`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    setData(result.data);
  };

  const rejectApi = async () => {
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/rfq/${data.id}/reject`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    fetchApi();
  };

  const acceptRFQ = async (respondsID) => {
    const toastID = toast.loading("Processing...");
    const response = await fetch(`${baseUrl}/rfq/${respondsID}/rfqOrder`, {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");
    // toast.dismiss(toastID);
    fetchApi();
  };

  const respondApi = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("price", price);
    formData.append("qty", quantity);
    formData.append("documents", doc);
    formData.append("note", note);
    formData.append("rfq_id", data.id);

    const toastID = toast.loading("Sending...");

    setEnable(true);

    const response = await fetch(`${baseUrl}/rfq/respond`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });

    const result = await response.json();

    if (result.error) {
      setEnable(false);
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);
    handleClose();
    fetchApi();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpeg") ||
      (file && file.type === "image/png") ||
      (file && file.type === "image/webp") ||
      (file && file.type === "image/jpg") ||
      (file && file.type === "application/pdf")
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 400000) {
          setDoc("");
          attachRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 400kb."
          );
        } else {
          setDoc(file);
        }
      };
    } else {
      setDoc("");
      attachRef.current.value = null;
      toast.error("Please select a valid image or pdf format.");
    }
  };

  useEffect(() => {
    fetchApi();
    return () => toast.dismiss();
  }, []);

  return (
    <>
      <section className="buyers-section">
        <NavbarAuth />
        <div className="buyers-section-div">
          <div className="bsd-left">
            {/* <BuyersSidebar n={7} /> */}
            <BuyersSidebar />
          </div>
          <div className="bsd-right rfq profile_page">
            <div className="rfq-page pt-5">
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-input">
                    <div className="inputs">
                      <label>RFQ Name</label>
                      <span className="text-capitalize">{data.rfq?.label}</span>
                    </div>
                    <div className="inputs">
                      <label>RFQ Till Date</label>
                      <span>
                        <Moment format="D-MMMM-Y">{data.opentill}</Moment>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="form-input">
                    <div className="inputs">
                      <label>Seller's Name</label>
                      <span className="text-capitalize">
                        {data.seller?.vendor.company}
                      </span>
                    </div>
                    <div className="inputs">
                      <label>Delivery Address</label>
                      {/* <span className="text-capitalize">{data.rfq?.vendor.vendor.address}</span> */}
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="form-input">
                    <div className="inputs">
                      <label>Order Type</label>
                      <span className="text-capitalize">
                        {data.rfq?.order_type?.replace("_", " ")}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="form-input">
                    <div className="inputs">
                      <label>Payment Type</label>
                      <span className="text-capitalize">
                        {data.rfq?.payment_type}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="form-input">
                    <div className="inputs">
                      <label>RFQ Status</label>
                      <span
                        className={`text-capitalize btn btn-${
                          data.status == "ongoing" || data.status == "accept"
                            ? "success"
                            : "danger"
                        } text-white`}
                      >
                        {data.status}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="form-input">
                    <div className="inputs">
                      <label>Payment Mode</label>
                      <span className="text-capitalize">
                        {data.rfq?.payment_mode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="column2 pt-4">
                {/* <div className="inputs">
                  <label>Payment Details</label>
                  <span className="text-capitalize">{data.rfq?.milestone || (data?.rfq?.pay)}</span>
                </div> */}

                <div className="inputs">
                  {data.rfq?.order_based && (
                    <>
                      <table className="table table-bordered w-50 mt-2">
                        <caption>Order Status Based Milestone</caption>
                        <thead>
                          <tr>
                            <th>Label</th>
                            <th>Days</th>
                            <th>Amount</th>
                            <th>Payment Status</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>Placement</td>
                            <td>{data.rfq?.order_based.placement_day}</td>
                            <td>{data.rfq?.order_based.placement_amount}%</td>
                            <td className="text-capitalize">
                              {data.rfq?.order_based.status}
                            </td>
                          </tr>
                          <tr>
                            <td>Fabrication</td>
                            <td>{data.rfq?.order_based.fabrication_day}</td>
                            <td>{data.rfq?.order_based.fabrication_amount}%</td>
                            <td className="text-capitalize">
                              {data.rfq?.order_based.status}
                            </td>
                          </tr>
                          <tr>
                            <td>Dispatched</td>
                            <td>{data.rfq?.order_based.dispatched_day}</td>
                            <td>{data.rfq?.order_based.dispatched_amount}%</td>
                            <td className="text-capitalize">
                              {data.rfq?.order_based.status}
                            </td>
                          </tr>
                          <tr>
                            <td>QC</td>
                            <td>{data.rfq?.order_based.qc_day}</td>
                            <td>{data.rfq?.order_based.qc_amount}%</td>
                            <td className="text-capitalize">
                              {data.rfq?.order_based.status}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}

                  {data.rfq?.cycle_based &&
                    data.rfq?.cycle_based.length > 0 && (
                      <>
                        <table className="table table-bordered w-50 mt-2">
                          <caption>Cycle Based Milestone</caption>
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Amount</th>
                              <th>Status</th>
                            </tr>
                          </thead>

                          <tbody>
                            {data.rfq?.cycle_based.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.days}</td>
                                  <td>{item.amount}%</td>
                                  <td className="text-capitalize">
                                    {item.status}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </>
                    )}
                </div>
              </div>

              <div
                className="pdt_Detail form-inputs"
                style={{ paddingTop: "40px" }}
              >
                <h4>Product Detail </h4>
                <h6 className="text-capitalize">
                  {data.product?.name}{" "}
                  <span className="text-capitalize">
                    {"["} Product price :{" "}
                    {formatter.format(data?.product?.max_price)} {"]"} {"["}{" "}
                    Minimum RFQ QTY : {data?.product?.min_quantity} {"]"} {"["}{" "}
                    Product Unit : {data?.product?.unit}
                    {"]"}
                    {data.product?.catalog?.category_id?.name}
                  </span>
                </h6>
                <div className="inputs paddingInputs20">
                  <label>RFQ Quoted Price </label>
                  <span className="text-capitalize">
                    {formatter.format(data?.rate?.price)} Per {data?.rate?.unit}
                  </span>
                </div>
                <div className="inputs paddingInputs20">
                  <label>RFQ Quoted Quantity </label>
                  <span className="text-capitalize">
                    {data.rate?.quantity} {data.rate?.unit}
                  </span>
                </div>
                <div className="inputs paddingInputs20">
                  <label>Note</label>
                  <span className="">{data.rfq?.note}</span>
                </div>
              </div>

              {/* <div className="additional">
          <h6>Attach Files</h6>
          <div className="inputs">
            <label className="label">Important Document.pdf </label>
            <span>
              <a href="#/rfq">Download</a>
            </span>
          </div>
          <div className="inputs">
            <label className="label">Important Document.pdf </label>
            <span>
              <a href="#/rfq">Download</a>
            </span>
          </div>
        </div> */}
              <div
                className="pdt_Detail form-inputs"
                style={{ paddingTop: "40px" }}
              >
                <h4>Responses </h4>
              </div>
              <Accordion defaultActiveKey="0" flush>
                {data.responds &&
                  data.responds.length > 0 &&
                  data.responds.map((item, index) => {
                    return (
                      <Accordion.Item eventKey={index} key={index}>
                        <Accordion.Header>
                          <div className="top">
                            <p className="text-capitalize">
                              {item.role == "seller"
                                ? data.seller?.vendor.company
                                : data.rfq?.vendor?.vendor?.company}
                            </p>
                            <p className="text-capitalize">{item.status}</p>
                            <p>
                              <Moment format="D-MMMM-Y hh:mm a">
                                {item.created_at}
                              </Moment>
                            </p>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="bottom">
                            <div className="account">
                              <div className="detail">
                                <label> Product Price </label>{" "}
                                <span className="text-capitalize">
                                  {formatter.format(item.price)} Per{" "}
                                  {data?.rate?.unit}
                                </span>
                              </div>
                              {/* <div className="detail">
                                <label> Landed Cost</label>{" "}
                                <span>
                                  ₹{" "}
                                  {data.rfq?.price_type == "landed"
                                    ? data.product?.landed_cost
                                    : 0}
                                </span>
                              </div> */}
                            </div>
                            <div className="account">
                              <div className="detail">
                                <label> Quantity</label>{" "}
                                <span className="text-capitalize">
                                  {item.qty} {data.rfq?.unit}
                                </span>
                              </div>
                            </div>
                            {/* <div className="account">
                              <div className="detail">
                                <label> Product GST</label>{" "}
                                <span>{data.product?.gst || "---"}%</span>
                              </div>
                            </div> */}

                            {item.documents && (
                              <div className="account">
                                <div className="detail">
                                  <label>Attachment</label>
                                  <span>
                                    <a
                                      href={item.documents?.url}
                                      target="_blank"
                                    >
                                      Download
                                    </a>
                                  </span>
                                </div>
                              </div>
                            )}

                            <div className="btn-input">
                              {data.status == "reject" ||
                              data.status == "accept" ? (
                                <button disabled={true} className="response">
                                  Respond
                                </button>
                              ) : (
                                <button
                                  className="continue"
                                  style={{ width: "fit-content" }}
                                  onClick={handleShow}
                                >
                                  Respond
                                </button>
                              )}

                              {item.role == "seller" && (
                                <button
                                  disabled={
                                    data.status == "reject" ||
                                    data.status == "accept"
                                  }
                                  className="accept"
                                  onClick={() => acceptRFQ(item.id)}
                                >
                                  Accept
                                </button>
                              )}
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
              </Accordion>

              <div
                className="d-flex justify-content-end"
                style={{ margin: "50px 0 auto auto", width: "60%" }}
              >
                <button
                  className="cancel"
                  disabled={data.status == "reject" || data.status == "accept"}
                  onClick={handleShow}
                >
                  Respond
                </button>
                <button
                  disabled={data.status == "reject" || data.status == "accept"}
                  className="continue"
                  onClick={rejectApi}
                >
                  Reject RFQ
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* modal popup */}
      <Modal
        className="response-modal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Respond RFQ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={respondApi}>
            <div className="form-control mb-3">
              <label>Product Price *</label>
              <input
                type="text"
                onInput={(e) => setPrice(e.target.value)}
                name="Price"
                required
              />
            </div>
            <div className="form-control mb-3">
              <label>Quantity *</label>
              <input
                type="text"
                onInput={(e) => setQuantity(e.target.value)}
                name="Quantity"
                required
              />
            </div>
            <div className="form-control mb-3">
              <label>Additional Attachment</label>
              <input
                type="file"
                ref={attachRef}
                onChange={handleFileChange}
                name="Cost"
              />
            </div>
            <div className="form-control mb-3">
              <label>Additional Info</label>
              <textarea
                rows="3"
                onInput={(e) => setNote(e.target.value)}
                type="text"
                name="Info"
              />
            </div>

            <div className="form-btn">
              <button className="cancel" onClick={handleClose}>
                Cancel
              </button>
              <button type="submit" className="continue">
                Send
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RfqSeller;
