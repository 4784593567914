import React from "react";
import Navbar from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import { RemoveRedEye } from "@mui/icons-material";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Slider from "./Slider";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import { AuthContext } from "../../../context/AuthContext";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { useState } from "react";
import { useContext } from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import { useRef } from "react";
import { useEffect } from "react";

const Welcome = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const { auth, setAuth } = useContext(AuthContext);

  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [show, setShow] = useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    setResetEmail(email);
  };

  const login = async (e) => {
    e.preventDefault();
    const toastID = toast.loading("Processing...");

    const response = await fetch(`${baseUrl}/login`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, result.message, "success");

    const token = localStorage.setItem("token_web", result.data.token);
    const user = localStorage.setItem(
      "auth_user",
      JSON.stringify(result.data.user)
    );

    setAuth(() => {
      return { user, token };
    });

    // window.location.reload();

    navigate("/dashboard");
  };

  const forgot = async (e) => {
    e.preventDefault();

    const toastID = toast.loading("Processing...");
    setError(false);
    setErrorText("");

    const response = await fetch(`${baseUrl}/password/email`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ email: resetEmail }),
    });

    const result = await response.json();

    if (result.error) {
      setError(true);
      setErrorText(result.message);
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toasterUpdate(toastID, "Password reset link sent to your email", "success");
  };

  const token = localStorage.getItem("token_web");

  useEffect(() => {
    if (token) {
      navigate("/account");
    }
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#fff",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <section className="welcome_page">
        <Navbar />
        <div className="container">
          <form onSubmit={login} className="box-display">
            <div className="leftwel">
              <h1>Welcome </h1>
              <p>
                Welcome to Rasayan Kart, your trusted online marketplace for
                buying and selling chemicals, connecting businesses across
                India/Globe.
              </p>
              <div className="form-control mb-4">
                <label>Email ID</label>
                <input
                  type="text"
                  value={email}
                  onInput={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                  name="name"
                  className="form-control"
                />
              </div>
              <div className="form-control">
                <label>Password</label>
                <input
                  type="password"
                  name="Password"
                  value={password}
                  required
                  onInput={(e) => {
                    setPassword(e.target.value);
                  }}
                  className="form-control"
                />
                <small onClick={handleShow}>Forgot Password</small>
              </div>
              <button className="account" type="submit">
                Login
              </button>
              <Link to="/register" className="dont">
                Don’t Have An Account ?
              </Link>
            </div>
            <div className="rightwel">
              <h1>Reduce Cost. Increase Profits</h1>
              <p>
                Unlock your one-stop solution for online chemical buying and
                selling. Explore our vast selection, empowering business growth
                with every transaction.
              </p>
              <Slider />
            </div>
          </form>
        </div>
      </section>
      <Modal
        open={show}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Forgot Password
          </Typography>
          <div style={{ marginTop: "20px" }}>
            <TextField
              id="standard-number"
              error={error}
              label={!error ? "email" : "error"}
              style={{ width: "100%" }}
              helperText={errorText}
              type="email"
              defaultValue={email}
              onInput={(e) => setResetEmail(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
            />
          </div>
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button variant="outlined" onClick={forgot}>
              Send Reset Link
            </Button>
          </div>
        </Box>
      </Modal>
      <Footer />
    </>
  );
};

export default Welcome;
