import "./seller.css";
import "../Components/Tabs/tabs.css";
// import SellerProfile from '../Components/SellerProfile/SellerProfile';
import Navbar from "../Components/Navbar/Navbar";
import Dp from "../../../assets/dp.png";
import Footer from "../../landing_page/Footer";
import Newpdf from "../Components/Pdf/Newpdf";
import Product from "../Components/Product/Product";
import ProductImg from "../../../assets/product.png";
import Sellerbanner from "../../../assets/sellerbanner.png";
import Conbanner from "../../../assets/contactbanner.png";
import { useMemo, useState } from "react";
import { Contact, ContactForm } from "../../landing_page/Contact";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { useParams, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import Pagination from "../../../component/Pagination";
import ProductRelatedCard from "../Components/Product/ProductRelatedCard";

function Seller() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");
  const [toggleState, setToggleState] = useState(Number(tab) || 1);
  const [data, setData] = useState({});
  const [products, setProducts] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const { id } = useParams();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const fetchApi = async () => {
    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/seller/page/${id}`);
    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return [];
    }
    toast.dismiss(toastID);
    return result.data;
  };

  const fetchProduct = async () => {
    const response = await fetch(
      `${baseUrl}/catalog/${id}/seller/?page=${currentPage}`,
      {
        headers: {
          Accept: "application/json",
        },
      }
    );
    const result = await response.json();
    if (result.error) {
      toast.error(result.message);
      return [];
    }
    setLastPage(result.data.last_page);
    return result.data.data;
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useMemo(async () => {
    const result = await fetchApi();
    setData(result);
  }, []);


  useMemo(async () => {
    const result = await fetchProduct();
    setProducts(result);
  }, [currentPage]);

  return (
    <>
      <Navbar />
      <section className="seller-tabs-section">
        <div className="seller-tabs-content-container">
          <div className="seller-tab-btn-container">
            <div className="dp-img-c">
              <img
                src={data.doc && data.doc[0]?.url}
                onError={(e) => (e.target.src = Dp)}
                alt=""
                width={"100px"}
                height={"auto"}
              />
            </div>
            <div className="df stbc-c">
              <div
                onClick={() => {
                  toggleTab(1);
                }}
                className={
                  toggleState === 1
                    ? "seller-tab-btn tab-active"
                    : "seller-tab-btn"
                }
              >
                About us
              </div>
              <div
                onClick={() => {
                  toggleTab(2);
                }}
                className={
                  toggleState === 2
                    ? "seller-tab-btn tab-active"
                    : "seller-tab-btn"
                }
              >
                Contact us
              </div>
              <div
                onClick={() => {
                  toggleTab(3);
                }}
                className={
                  toggleState === 3
                    ? "seller-tab-btn tab-active"
                    : "seller-tab-btn"
                }
              >
                Products
              </div>
              <div
                onClick={() => {
                  toggleTab(4);
                }}
                className={
                  toggleState === 4
                    ? "seller-tab-btn tab-active"
                    : "seller-tab-btn"
                }
              >
                Documents
              </div>
            </div>
          </div>

          <div className="seller-content-container">
            <div
              className={
                toggleState === 2
                  ? "seller-content content-active"
                  : "seller-content"
              }
            >
              <img src={Conbanner} alt="" className="seller-banner" />
              <div className="w8-div w-75 mt-md-5 mx-auto">
                <ContactForm company={data.vendor?.company} />
              </div>
            </div>

            <div
              className={
                toggleState === 1
                  ? "seller-content content-active"
                  : "seller-content"
              }
            >
              <img className="seller-banner" src={Sellerbanner} alt="" />
              <div className="w8-div">
                <h1>{data.vendor?.company}</h1>
                <p>{data.vendor?.about_company}</p>
              </div>
            </div>

            <div
              className={
                toggleState === 3
                  ? "seller-content content-active"
                  : "seller-content"
              }
            >
              <div className="w8-div">
                <div className="shop-card-container">
                  {products.map((item, index) => {
                    return (
                      <ProductRelatedCard
                        key={index}
                        img={item.url}
                        description={item.description}
                        name={item.name}
                        company={item?.company?.company}
                        id={item.id}
                        redirect={`/product/${item.id}/seller`}
                        slug={item.slug}
                        price={item.max_price}
                      />
                    );
                  })}
                </div>
              </div>

              <Pagination
                handlePageChange={handlePageChange}
                lastPage={lastPage}
                currentPage={currentPage}
              />
            </div>

            <div
              className={
                toggleState === 4
                  ? "seller-content content-active new-seller-content"
                  : "seller-content new-seller-content"
              }
            >
              <div className="document-card-container w-100">
                {/* <div style={{background:"#006ff2", marginBottom:"10px", color:"#fff", padding:"10px"}} className="KYCSec">
                            <p style={{margin:"0px"}}>My KYC Documents</p>
                        </div> */}
                <img src={Sellerbanner} alt="" className="seller-banner" />

                <div className="w8-div">
                  <div className="pdf-container jcb">
                    {data.doc?.map((item, index) => (
                      <Newpdf
                        key={index}
                        name={item.label?.replace("_", " ").toUpperCase()}
                        url={item.url}
                        size="2.5mb"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Seller;
