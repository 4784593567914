import './login.css';
import Navbar, { NavbarAuth } from '../Components/Navbar/Navbar';
import {Link} from 'react-router-dom' ;

function IP({ array }) {
    return (
        array.map((array, index) => (
            <div key={index} className="input-container-af">
                <input type={array.type} name={array.name} placeholder={array.name} />
            </div>
        ))
    );
}

export default function Accountform() {
    let values = [
        {
            name: 'Name',
            type: 'text'
        },
        {
            name: 'Secondary Name (Optional)',
            type: 'text'
        },
        {
            name: 'Email Id',
            type: 'text'
        },
        {
            name: 'Gst No.',
            type: 'text'
        },
        {
            name: 'Udhayam Adhaar',
            type: 'text'
        },
        {
            name: 'Explosive Licence No.',
            type: 'text'
        },
        {
            name: 'Address',
            type: 'text'
        },
        {
            name: 'Secondary Contact (Optional)',
            type: 'text'
        },
        {
            name: 'PAN No.',
            type: 'text'
        },
        {
            name: 'TAN No.',
            type: 'text'
        },
        {
            name: 'Drung Licence No.',
            type: 'text'
        },
        {
            name: 'Prohibition Excise',
            type: 'text'
        },
    ]
    return (
        <>
            <NavbarAuth />
            <section className="regi-form-container">
                <form action="" className="account-form">
                    <h1>User Profile Setup</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis excepturi architecto consectetur cupiditate recusandae.</p>

                    <div className="af-container">
                        <IP array={values} />
                        <Link  to='/shop' className="conbtn-g afb">Complete my profile</Link>
                    </div>

                </form>
            </section>
        </>
    );
};
