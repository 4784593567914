import "./landing.css";
import "./sidebar.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import PopupNumber from "../dashboard/Login/PopupNumber";
import PopupOtp from "../dashboard/Login/PopupOtp";

import CloseIcon from "@mui/icons-material/Close";

import { useEffect, useState } from "react";
import { NavbarAuth } from "../dashboard/Components/Navbar/Navbar";
function Aboutus() {
  const token = localStorage.getItem("token_web");

  return (
    <>

      <section className="landing-sec-2New">
        {token ? <NavbarAuth /> : <Navbar />}

        <div className="contactUsOuter">
          <div className="contactUsInner">
            <div className="ConatctleftSec">
              <h2>About us</h2>
            </div>
          </div>
        </div>
      </section>

      <div className="ContactContentSection aboutus" style={{ position: "relative" }}>

        <div className="ContactContentSectionRight aboutus">
          <div className="aboutUsSectionOter">
            <h4 style={{ textAlign: "left" }}>About Company</h4>

            <p className="ms-0">
              At Rasayankart, we are more than just an ecommerce platform — we are your trusted partner in the world of B2B chemical raw materials. With a passion for excellence and a commitment to quality, we have established ourselves as a leading provider in the industry. Our mission is to streamline the procurement process for businesses, connecting buyers with a diverse range of high-quality chemical raw materials seamlessly.
            </p>
            <p className="ms-0">
              What sets Rasayankart apart is our unwavering dedication to customer satisfaction and reliability. We understand the intricacies of the chemical industry, and our platform is designed to offer a comprehensive and user-friendly experience. From sourcing to delivery, we prioritize efficiency, ensuring that businesses can access the essential raw materials they need to thrive.
            </p>
            <p className="ms-0">
              At the core of Rasayankart is a team of industry experts and professionals who share a common goal — to redefine the standards of chemical procurement. We believe in fostering transparent and collaborative relationships, empowering businesses to navigate the complexities of the chemical supply chain. Join us on this journey, and experience a new era of convenience and trust in sourcing your B2B chemical raw materials with Rasayankart.
            </p>
          </div>
          {/* end of aboutUsSectionOter */}

          <div className="aboutUsSectionOter">
            <h4 style={{ textAlign: "left" }}>Our Services</h4>
            <p className="ms-0">
              Rasayankart takes pride in offering a comprehensive suite of services tailored to meet the diverse needs of our valued customers. Our platform not only facilitates seamless transactions for B2B chemical raw materials but also provides additional value-added services. From expert insights on market trends to personalized assistance in navigating regulatory landscapes, we are committed to supporting businesses at every step of their chemical procurement journey. At Rasayankart, we go beyond being a transactional platform – we strive to be your strategic partner, ensuring your success in the dynamic world of chemical sourcing.
            </p>

            <h4 style={{ textAlign: "left" }}>Buying Dashboard</h4>
            <p className="ms-0">
              Buyers, elevate your procurement process with Rasayankart's intuitive buying dashboard. Effortlessly browse through a diverse catalog of B2B chemical raw materials, compare options, and make well-informed purchasing decisions. The buying dashboard is designed to enhance efficiency, providing a centralized platform for managing orders, tracking shipments, and ensuring a smooth transactional experience. At Rasayankart, we prioritize simplicity and transparency in our buying dashboard, empowering businesses to source the right chemical raw materials with confidence.
            </p>

            <h4 style={{ textAlign: "left" }}>Selling Dashboard</h4>
            <p className="ms-0">
              For suppliers, our selling dashboard is a powerful tool for managing and optimizing your offerings. Easily list your products, track sales performance, and gain valuable insights into market demand. Our user-friendly interface ensures a seamless experience, allowing suppliers to efficiently showcase their products to a vast network of potential buyers. Rasayankart's selling dashboard is more than just a tool – it's your gateway to expanding your reach and maximizing the impact of your chemical raw material offerings.
            </p>

            <h4 style={{ textAlign: "left" }}>Forum</h4>
            <p className="ms-0">
              Building a community of like-minded professionals is at the heart of Rasayankart's vision. Our interactive forum provides a space for industry stakeholders to connect, share experiences, and engage in meaningful discussions. From best practices in chemical procurement to navigating supply chain challenges, our forum fosters collaboration and knowledge exchange. Join the conversation on Rasayankart's forum, where the collective wisdom of the community enhances the overall experience of sourcing and selling B2B chemical raw materials.
            </p>

            <h4 style={{ textAlign: "left" }}>Information</h4>
            <p className="ms-0">
              Knowledge is power, and at Rasayankart, we believe in empowering our users with the information they need to make informed decisions. Our platform serves as an information hub, offering valuable resources, articles, and updates on the latest advancements in the chemical industry. Whether you are seeking technical specifications, regulatory guidelines, or market analyses, our information hub is designed to be a reliable source for all your informational needs. Stay ahead of the curve with Rasayankart as your go-to destination for comprehensive insights into the B2B chemical raw materials landscape.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Aboutus;
