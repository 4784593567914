import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import { Checkbox } from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Clear, Edit } from "@mui/icons-material";
import { RfqContext } from "../../../context/RfqContext";
import { useContext, useEffect, useState } from "react";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";

const FormStep5 = () => {
  const navigate = useNavigate();

  const [data, setData] = useState(false);

  const { rfq_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const token = localStorage.getItem("token_web");

  const fetchApi = async () => {
    const toastID = toast.loading("Fetching...");
    const response = await fetch(`${baseUrl}/rfq/${id}/draft`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    toast.dismiss(toastID);
    setData(() => result.data);
  };

  const submitRfq = async (e) => {
    e.preventDefault();

    const toastID = toast.loading("Submitting RFQ");
    const response = await fetch(`${baseUrl}/rfq/${id}/publish`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toasterUpdate(toastID, result.message, "success");
    navigate(`/pending`);
  };

  function back() {
    navigate(`/rfqstep4/${rfq_id}?id=${id}`);
  }

  useEffect(() => {
    fetchApi();

    return () => toast.dismiss();
  }, []);

  return (
    <>
      <NavbarAuth />
      <section className="profile_page">
        <h1>RFQ Form</h1>
        <p className="text-center">
          Confidently review and submit your RFQ. Simplify the process, unlock a
          world of possibilities for your chemical procurement needs.
        </p>

        <ul
          className="summary step2 step3 step4 step5"
          style={{ padding: "20px 0" }}
        >
          <li>Basic Details</li>
          <li>Product Request</li>
          <li>Product Request Details</li>
          <li>Seller Selection</li>
          <li>Review Form</li>
        </ul>
        <form onSubmit={submitRfq}>
          <div className="form-inputs">
            <div className="inputs">
              <label>RFQ Name</label>
              <span className="text-capitalize">{data.label}</span>
            </div>
            <div className="inputs">
              <label>RFQ Till Date</label>
              <span>{data.opentill}</span>
            </div>
          </div>
          <div className="form-inputs" style={{ paddingTop: "40px" }}>
            <div className="inputs">
              <label>Product Price Type</label>
              <span className="text-capitalize">{data.order_type}</span>
            </div>
            <div className="inputs">
              <label>Payment Type</label>
              <span className="text-capitalize">{data.payment_type}</span>
            </div>
            <div className="inputs">
              <label>Payment Details</label>
              <span className="text-capitalize">
                {data.payment_type == "credit" ? "milestone" : "spot payment"}
              </span>
            </div>
          </div>
          <div
            className="pdt_Detail form-inputs"
            style={{ paddingTop: "40px" }}
          >
            <label>Product Details </label>

            {data?.added_product?.map((item) => {
              return (
                <>
                  <h6
                    style={{ color: "#000", marginBottom: "30px" }}
                    className="text-capitalize"
                  >
                    {item?.catalog?.name}
                    <span>{`₹${item.price} Per ${item.unit} - Quantity ${item.quantity}`}</span>
                  </h6>
                </>
              );
            })}
          </div>
          {/* table-cNoScroll */}

          <div className=" custom-file-upload">
            <label className="label">Additional Details</label>
            <textarea
              placeholder="Type your Message"
              rows="5"
              onInput={(e) => setData({ ...data, note: e.target.value })}
            />
          </div>
          {/*<div className=" custom-file-upload">
            <label className="label">Attach Files </label>
            <input id="file-upload" type="file" />
          </div>

<Link className="more">Add More +</Link>*/}

          <div
            className="form-control"
            style={{ margin: "0 0 auto auto", width: "50%" }}
          >
            <button className="cancel" type="button" onClick={back}>
              Cancel
            </button>
            <button className="continue" type="submit">
              Send
            </button>
          </div>
        </form>
      </section>

      <Footer />
    </>
  );
};

export default FormStep5;
