import "./buyerssidebar.css";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import dashboardIcon from "../../../../assets/dashboard.png";
import dashboardIconBlue from "../../../../assets/dashboard-alt.png";
import myRfqIcon from "../../../../assets/myrfq.png";
import myRfqIconBlue from "../../../../assets/myrfq-alt.png";
import addressIcon from "../../../../assets/address.png";
import addressIconBlue from "../../../../assets/address-alt.png";
import supportIcon from "../../../../assets/support.png";
import supportIconBlue from "../../../../assets/support-alt.png";
import icon1White from "../../../../assets/File_dock.png";
import icon1blue from "../../../../assets/File_dock-color.png";

import icon2White from "../../../../assets/Bag.png";
import icon2blue from "../../../../assets/Bag-color.png";

import icon3White from "../../../../assets/Wallet_alt.png";
import icon3blue from "../../../../assets/Wallet_alt-color.png";

import icon4White from "../../../../assets/User_add.png";
import icon4blue from "../../../../assets/User_add-color.png";

import icon5White from "../../../../assets/confirm-order.png";
import icon5blue from "../../../../assets/confirm-order-color.png";

import icon6White from "../../../../assets/pending-order.png";
import icon6blue from "../../../../assets/pending-order-color.png";

import icon7White from "../../../../assets/documentNewBy.png";
import icon7blue from "../../../../assets/documentNewBy-color.png";

import icon8blue from "../../../../assets/logOut.png";
// import * as React from 'react';
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';

import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { useEffect } from "react";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function BuyersSidebar(props) {
  const [Sidebar, setSidebarActive] = useState(false);

  const SidebarFunc = () => {
    Sidebar ? setSidebarActive(false) : setSidebarActive(true);
  };

  const shoot = () => {
    window.location.href = "https://seller.rasayankart.in/";
  };

  useEffect((useEffect) => {
    $("#MyOrder85").click(function () {
      $("#MyOrder82").slideDown();
    });
    $("#showMenuOrds1").click(function () {
      $("#MyOrder82").show();
    });
    $("#MyAccount85").click(function () {
      $("#MyAccount82").slideDown();
    });
  }, []);
  return (
    <div
      className={
        Sidebar === true ? "buyers-sidebar active-sidebar" : "buyers-sidebar"
      }
    >
      <div className="buyers-sidebar-heading">Buyer's Profile</div>
      <Link
        to="/dashboard"
        className={
          props.n === 14 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={dashboardIcon}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={dashboardIconBlue}
          alt="icon"
        />
        Dashboard
      </Link>
      <Link
        to="/rfqstep1"
        className={
          props.n === 0 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={icon1White}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={icon1blue}
          alt="icon"
        />
        Create RFQ
      </Link>
      {/* <Link id="MyOrder85" to="" className={props.n === 0 ? "buyers-options active-opn" : "buyers-options"}>My Orders</Link> */}

      <Link
        to="/pending"
        className={
          props.n === 7 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={myRfqIcon}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={myRfqIconBlue}
          alt="icon"
        />
        My RFQs
      </Link>

      <Link
        to=""
        id="MyOrder85"
        className={
          props.n === 0 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={icon2White}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={icon2blue}
          alt="icon"
        />
        My Orders
      </Link>

      <div className="hideSubMenu" id="MyOrder82">
        <Link
          id="showMenuOrds1"
          to="/orders"
          className={
            props.n === 2 ? "buyers-options active-opn" : "buyers-options"
          }
        >
          <img
            className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
            src={icon5White}
            alt="icon"
          />
          <img
            className="menuAllIcons menuAllIcons-active icon4blueShowHide"
            src={icon5blue}
            alt="icon"
          />
          Orders
        </Link>
        {/*<Link id="showMenuOrds2" to="/orders" className={props.n === 3 ? "buyers-options active-opn" : "buyers-options"}>
                    <img className='menuAllIcons menuAllIcons-active icon4WhiteShowHide' src={icon6White} alt="icon" />
                    <img className='menuAllIcons menuAllIcons-active icon4blueShowHide' src={icon6blue} alt="icon" />
    Pending</Link>*/}
      </div>

      <Link
        to="/wallet"
        className={
          props.n === 4 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={icon3White}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={icon3blue}
          alt="icon"
        />
        RKWallet
      </Link>
      <Link
        to="/address"
        className={
          props.n === 40 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={addressIcon}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={addressIconBlue}
          alt="icon"
        />
        Address
      </Link>
      <Link
        to="/support"
        className={
          props.n === 10 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={supportIcon}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={supportIconBlue}
          alt="icon"
        />
        Support
      </Link>

      <Link
        id="MyAccount85"
        to="/account"
        className={
          props.n === 1 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
          src={icon4White}
          alt="icon"
        />
        <img
          className="menuAllIcons menuAllIcons-active icon4blueShowHide"
          src={icon4blue}
          alt="icon"
        />
        My Account
      </Link>

      <div className="hideSubMenu" id="MyAccount82">
        <Link
          to="/documents"
          className={
            props.n === 5 ? "buyers-options active-opn" : "buyers-options"
          }
        >
          <img
            className="menuAllIcons menuAllIcons-active icon4WhiteShowHide"
            src={icon7White}
            alt="icon"
          />
          <img
            className="menuAllIcons menuAllIcons-active icon4blueShowHide"
            src={icon7blue}
            alt="icon"
          />
          Documents
        </Link>
      </div>
      <Link
        to="/"
        className={
          props.n === 6 ? "buyers-options active-opn" : "buyers-options"
        }
      >
        <img
          className="menuAllIcons menuAllIcons-active"
          src={icon8blue}
          alt="icon"
        />
        Logout
      </Link>

      <div className="vrn">
      <div>
          Buyer
        </div>
        <FormGroup onClick={shoot} className="df">
          <FormControlLabel
            className="hehe-form"
            control={<IOSSwitch sx={{ m: 1 }} />}
            label=""
            // defaultChecked
          />
        </FormGroup>
        <div>
          Seller
        </div>
      </div>

      <button
        onClick={SidebarFunc}
        className={Sidebar === true ? "callbtn active-callbtn" : "callbtn"}
      >
        <AddIcon className="callbtn-i" />
      </button>
    </div>
  );
}

export default BuyersSidebar;
