import React from 'react'

function MobInputs(props) {
  return (
    <div className="mob-input-container">
    <label htmlFor={props.name}>{props.title}</label>
    <input type={props.type} name={props.name} id={props.name} required/>
</div>
  )
}

export default MobInputs ;