import React, { useState, useEffect, useCallback } from "react";

import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import "../Buyers/buyers.css";
import { Link } from "react-router-dom";
import "react-calendar/dist/Calendar.css";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import Pagination from "../../../component/Pagination";
import Moment from "react-moment";
import Greeting from "../../../component/Greeting";
import formatter from "../../../utils/formatter";
import Chart from "react-apexcharts";
// import {useEffect} from "react";

const Tile = ({ title, int, bg }) => {
  return (
    <div className="col-12 col-md-3 dashboard-tile-container">
      <div
        className="dashboard-tile d-flex flex-column justify-content-center align-items-center rounded-3"
        style={{ height: "150px", backgroundColor: bg }}
      >
        <h3 style={{ fontWeight: "600", fontSize: "35px" }}>{int}</h3>
        <span style={{ fontWeight: "600" }}>{title}</span>
      </div>
    </div>
  );
};

const Tile2 = ({ title, int, bg }) => {
  return (
    <div className="col-12 dashboard-tile-container mb-3">
      <div
        className="dashboard-tile d-flex flex-column justify-content-center align-items-center rounded-3"
        style={{ height: "150px", backgroundColor: bg }}
      >
        <h3 style={{ fontWeight: "600", fontSize: "35px" }}>{int}</h3>
        <span style={{ fontWeight: "600" }}>{title}</span>
      </div>
    </div>
  );
};

const ChartComp = () => {
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  let options = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: labels,
      },
      yaxis: {
        labels: {
          formatter: function (val) {
            // Format the value here (e.g., add comma separator)
            return formatter.format(val); // Example: 1,000
          },
        },
      },
    },
    series: [
      {
        name: "Sales",
        data: values,
      },
    ],
  };

  const fetchGraph = async () => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/graph`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    setLabels((prev) => {
      return result.labels;
    });
    setValues((prev) => {
      return result.values;
    });
  };

  useEffect(() => {
    fetchGraph();
  }, []);

  return <Chart {...options} type="bar" width="100%" />;
};

function Dashboard() {
  const [data, setData] = useState([]);
  const [tiles, setTiles] = useState({});
  const [currentPage, setCurrentPage] = useState(1);

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/orders?page=${currentPage}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    setData(result.data.data);
  };

  const fetchTiles = async () => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/dashboard`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    setTiles(result.data);
  };

  useEffect((useEffect) => {
    $(".ConfirnFilt").click(function () {
      $(".Allfilterinner").slideDown("fast");
      $(".hideRageSliderDivfix").show();
    });
    $(".hideRageSliderDivfix, .history-btn").click(function () {
      $(".Allfilterinner").slideUp("fast");
      $(".hideRageSliderDivfix").hide();
    });
  }, []);

  useEffect(() => {
    fetchApi();
    fetchTiles();
  }, [currentPage]);

  const tilesData = [
    {
      title: "RFQs Generated",
      int: tiles?.rfq || 0,
      bg: "#f9dcc9",
    },
    {
      title: "RFQs Accepted",
      int: tiles?.accepted_rfqs || 0,
      bg: "#e2f9e0",
    },
    {
      title: "Open Tickets",
      int: tiles?.ticket || 0,
      bg: "#feebeb",
    },
    {
      title: "Wallet",
      int: formatter.format(tiles?.wallet || 0),
      bg: "#feebeb",
    },
  ];
  const tilesData2 = [
    {
      title: "Orders",
      int: tiles?.order || 0,
      bg: "#f9dcc9",
    },
    {
      title: "Orders Accepted",
      int: tiles?.order_accepted || 0,
      bg: "#e2f9e0",
    },
    {
      title: "Orders In Transit",
      int: tiles?.order_transit || 0,
      bg: "#fcffd5",
    },
    {
      title: "Orders Rejected",
      int: tiles?.order_rejected || 0,
      bg: "#f9e0e0",
    },
  ];

  return (
    <section className="buyers-section">
      <NavbarAuth />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={14} />
        </div>
        <div className="bsd-right bsd-right8247">
          <div className="px-3 pt-3">
            <Greeting />
          </div>

          <div className="row px-3" style={{ "--bs-gutter-y": "24px" }}>
            {tilesData &&
              tilesData?.map((item, key) => <Tile key={key} {...item} />)}
          </div>

          <div className="row px-3 mt-3">
            <div className="col-12 col-md-9">
              <ChartComp />
            </div>

            <div className="col-12 col-md-3 row p-0 flex-column">
              {tilesData2 &&
                tilesData2?.map((item, key) => <Tile2 key={key} {...item} />)}
            </div>
          </div>

          <div className="Outer-bsd-right8247">
            <div
              className="searchPendingItem"
              style={{ marginBottom: "20px" }}
            ></div>

            <div className="hideRageSliderDivfix"></div>
            <div
              className="table table-responsive w-100"
              style={{
                background: "#feebeb",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              <h4>Recent Orders</h4>
              <table className="table table-gray" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>ID</th>
                    <th style={{ textAlign: "left" }}>Product</th>
                    <th style={{ textAlign: "left" }}>Seller</th>
                    <th style={{ textAlign: "left" }}>Status</th>
                    <th style={{ textAlign: "left" }}>Order Date</th>
                    <th style={{ textAlign: "left" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index} style={{ padding: "10px" }}>
                        <td
                          style={{ textAlign: "left" }}
                          className="text-capitalize"
                        >
                          {item.order_id}
                        </td>
                        <td
                          style={{ textAlign: "left" }}
                          className="text-capitalize"
                        >
                          {item.product?.name}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {item.seller?.vendor?.company}
                        </td>
                        <td
                          style={{ textAlign: "left" }}
                          className="text-capitalize"
                        >
                          {item.order_status}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          <Moment format="D-MMMM-Y hh:mm a">
                            {item.created_at}
                          </Moment>
                        </td>
                        <td style={{ textAlign: "left", padding: 10 }}>
                          <Link to={`${item.id}/show`}>View</Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
