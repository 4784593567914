import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import ImgsearchIcon from "../../../assets/search.png";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { Link, useNavigate } from "react-router-dom";
import Pagination from "../../../component/Pagination";
import { useState } from "react";
import Moment from "react-moment";
import Greeting from "../../../component/Greeting";

const Pending = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [key, setKey] = useState("");
  const [status, setStatus] = useState("");

  const navigate = useNavigate();

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  const fetchApi = async () => {
    // const toastID = toast.loading("Processing...");

    const token = localStorage.getItem("token_web");
    const user = JSON.parse(localStorage.getItem("auth_user"));

    const response = await fetch(
      `${baseUrl}/rfq/${user.id}/user?limit=10&page=${currentPage}&key=${key}&status=${status}`,
      {
        method: "get",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await response.json();

    if (result.error) {
      // toasterUpdate(toastID, result.message, "error");
      toast.error(result.message);
      return;
    }

    setData(result.data.data);
    setLastPage(result.data.last_page);

    // toasterUpdate(toastID, result.message, "success");
  };

  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    fetchApi();
  }, [currentPage, key, status]);

  return (
    <>
      <section className="buyers-section">
        <NavbarAuth />
        <div className="buyers-section-div">
          <div className="bsd-left">
            <BuyersSidebar n={7} />
          </div>
          <div className="bsd-right pending">
            <Greeting />

            <div className="searchPendingItem" style={{ marginBottom: "20px" }}>
              <input
                type="text"
                placeholder="Search by RFQ ID"
                onInput={(e) => setKey(e.target.value)}
              />
              <img src={ImgsearchIcon} alt="d" />
            </div>

            <table className="table table-striped w-100">
              <tr>
                <th className="py-3 bg-light">ID</th>
                <th className="py-3 bg-light">Name</th>
                <th className="py-3 bg-light">RFQ Type</th>
                <th className="py-3 bg-light">RFQ Expires</th>
                <th className="py-3 bg-light">No. of Responses</th>
                <th className="py-3 bg-light">RFQ Created Date</th>
                <th className="py-3 bg-light" style={{ width: "100px" }}>
                  <select
                    name="status"
                    id=""
                    onChange={(e) => setStatus(e.target.value)}
                    className="form-select border-0 fw-bold p-0"
                  >
                    <option value="">Status</option>
                    <option value="draft">Draft</option>
                    <option value="open">Open</option>
                    <option value="expired">Expired</option>
                  </select>
                </th>
                <th className="py-3 bg-light">Details</th>
              </tr>

              {data &&
                data.map((item, index) => {
                  return (
                    <tr className="">
                      <td className="py-3">{item.rfq_id}</td>
                      <td className="text-capitalize py-3">{item.label}</td>
                      <td className="text-capitalize py-3">
                        {item.order_type}
                      </td>
                      <td className="text-capitalize py-3">
                        <Moment className="p-0" fromNow>{item.opentill}</Moment>
                      </td>
                      <td className="py-3">{item?.responds_count || 0}</td>
                      <td className="py-3">
                        <Moment className="p-0" format="D-MMMM-Y">{item.created_at}</Moment>
                      </td>
                      <td className="text-capitalize py-3">
                        {item.status == "expired" && "Expired"}
                        {item.status !== "created" &&
                          item.status !== "expired" &&
                          "Draft"}
                        {item.status == "created" && "Open"}
                      </td>
                      <td className="text-capitalize py-3 text-left">
                        <Link to={`/rfq/${item.rfq_id}?id=${item.id}`}>
                          view
                        </Link>

                        {item.status !== "created" &&
                          item.status !== "expired" && (
                            <Link to={`/rfqstep2/${item.rfq_id}?id=${item.id}`}>
                              Edit
                            </Link>
                          )}
                      </td>
                    </tr>
                  );
                })}
            </table>

            <Pagination
              handlePageChange={handlePageChange}
              lastPage={lastPage}
              currentPage={currentPage}
            />
          </div>
        </div>
      </section>
      {/* <div className="pendingrfq">
                <Navbar />
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>Collapsible Group Item #1</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                        <Typography>Collapsible Group Item #2</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                        <Typography>Collapsible Group Item #3</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor
                            sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                            sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Footer />
            </div> */}
    </>
  );
};

export default Pending;
