import React from 'react'
import './mob.css';
import { Link } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Navbar from '../../landing_page/Navbar';
import Footer from '../../landing_page/Footer';


function MobLog(props) {
    return (
        <>
        <Navbar/>
        <div className="moblogin-container">
            <Link to={'/'} className="back-arrow"><KeyboardBackspaceIcon /></Link>
            <div className="mob-heading">Enter Email Address to signin</div>

            <form>
                <div className="mob-input-container">
                    <label htmlFor="">Email</label>
                    <input type="text" />
                </div>
                <div className="mob-input-container">
                    <label htmlFor="">Password</label>
                    <input type="password" />
                </div>
                <div className="forget-p">Forget Password</div>

                <p className="blue-p">Sign in with otp</p>
                <Link to={'/shop'} className='conbtn-g mob-btn'>Continue</Link>
                <p className="black-p">New to RasayanKart? <Link to='/mnum' className='blue-p'>Register</Link></p>
            </form>

        </div>       
        <Footer /> 
        </>
    )
}

export default MobLog