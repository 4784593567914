import "./checkout.css";
import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import UploadimgN from "../../../assets/File_fill.png";
import DocumentImg from "../../../assets/Document.png";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Input,
  InputLabel,
} from "@mui/material";
import formatter from "../../../utils/formatter";

import {
  BrowserRouter as Router,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import Footer from "../../landing_page/Footer";
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { baseUrl } from "../../../utils/baseUrl";
import useRazorpay from "react-razorpay";
import { useCallback } from "react";

const CheckoutPending = () => {
  const Razorpay = useRazorpay();
  const navigate = useNavigate();
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState(false);
  const [paymentMode, setPaymentMode] = useState("online");
  const [wallet, setWallet] = useState(0);
  const [isWallet, setIsWallet] = useState(false);

  const [reference_number, setReference_number] = useState("");
  const { order_id } = useParams();

  const cart = sessionStorage.getItem("cart");

  const cartData = JSON.parse(cart);

  let user = localStorage.getItem("auth_user");

  user = JSON.parse(user);

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/order/${order_id}/show`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    setData(() => result.data);
  };

  const orderApi = async (payment_reference_no, modeOfPayment) => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Processing...");
    setLoading(true);

    const response = await fetch(`${baseUrl}/rfq/${order_id}/OrderPayment`, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        payment_mode: modeOfPayment,
        payment_reference: payment_reference_no,
      }),
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      setLoading(false);

      return;
    }
    sessionStorage.clear("cart");
    toasterUpdate(toastID, result.message, "success");
    navigate("/done");
  };

  const handlePayment = useCallback((price) => {
    // const order = await createOrder(params);
    const options = {
      key: "rzp_test_slxt3XjWOBThMM",
      amount: `${price * 100}`,
      currency: "INR",
      name: "RasayanKart",
      handler: (res) => {
        orderApi(res.razorpay_payment_id, "online");
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone,
      },
      notes: {
        address: "Rasayankart",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.open();
  }, []);

  const initiateCheckout = () => {
    if (!paymentMode) {
      toast.error("Please select a payment method.");
      return;
    }

    console.log('payment initiated');
    console.log(isWallet?'wallet':'not wallet')

    if (isWallet) {
      setPaymentMode("wallet");
      orderApi("", "wallet");
      return;
    }

    if (paymentMode == "online") {
      handlePayment(summary && summary.total);
    } else {
      orderApi(reference_number, "cheque");
    }
  };

  useEffect(() => {
    $("#onlineradio").click(function () {
      $(".InstructionsOnline").show();
      $(".InstructionsTransfer").hide();
      $("#CheckOutPayNowBTNTrans").hide();
      $("#CheckOutPayNowBTNOnline").show();
    });
    $("#transferradio").click(function () {
      $(".InstructionsOnline").hide();
      $(".InstructionsTransfer").show();
      $("#CheckOutPayNowBTNTrans").show();
      $("#CheckOutPayNowBTNOnline").hide();
    });
  }, []);

  useEffect(() => {
    fetchApi();

    return () => toast.dismiss();
  }, []);

  return (
    <React.Fragment>
      <section className="place-sec-1">
        <NavbarAuth />
        <h1 className="checkout_heading">Select Payment Method</h1>
        <div className="checkout-container">
          <div className="checkout-container-wraper">
            <div className="lt-container">
              <div className="mb-4">
                <FormControl className="d_flex">
                  <RadioGroup
                    className="d_flex flex_row"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="payment_mode"
                  >
                    <button className="checkout-btn">
                      <FormControlLabel
                        value="online"
                        id="onlineradio"
                        control={<Radio />}
                        onChange={(e) => setPaymentMode("online")}
                        label="Payment Through Online"
                      />
                    </button>
                    <button className="checkout-btn">
                      <FormControlLabel
                        value="cheque"
                        id="transferradio"
                        onChange={(e) => {
                          setIsWallet(false);
                          setPaymentMode("cheque");
                        }}
                        control={<Radio />}
                        label="Cheque /DD/ Bank Transfer"
                      />
                    </button>
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="rk-wallet-sec d-flex aic">
                <div className=" aic">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isWallet}
                        disabled={
                          data?.wallet?.balance < data?.total ||
                          paymentMode === "cheque"
                        }
                        onChange={(e) => {
                          setIsWallet(!isWallet);
                          setPaymentMode("wallet");
                        }}
                      />
                    }
                    label="RK Credit"
                  />
                </div>
                <div className="ms-auto amt-container">
                  <p className="amount">
                    {formatter.format(data?.wallet?.sanctioned_amount)}
                  </p>
                  <p className="mt-0 text-gray text-end">Balance</p>
                </div>
              </div>

              <div className="">
                <h3>Instructions :</h3>
                <ul className="instr-list InstructionsOnline">
                  <div className="text-center">
                    <img
                      src="images/qrcode.png"
                      alt="qr code payment"
                      style={{ maxWidth: "60%" }}
                    />
                  </div>
                  <li className="">
                    To debit amount from RK wallet, click in the box above
                  </li>
                  <li className="">Then click pay now button below</li>
                  <li className="">
                    Pay from your debit card/credit card /net banking
                  </li>
                  {/* <li className="">Then Pay from pay now button shown below </li>
                        <li className="">If you want to debit amount from your <span>RK wallet</span> so click shown above check box</li> */}
                </ul>

                <div className="instr-list InstructionsTransfer">
                  <ul className="instr-list InstructionsTransfer">
                    <li className="">
                      If you want to debit amount from your RK wallet so click
                      shown above check box
                    </li>
                    <li className="">
                      You can make the payment with the help of the information
                      given here
                    </li>
                    <li className="">
                      Account Name : Gowin Business Solution Pvt Ltd
                      <br />
                      Account No. : 900210011001339
                      <br />
                      IFSC code : GSCB0UGAC02
                      <br />
                      Bank Name : Gujarat Ambuja Co-op. Bank Ltd.
                      <br />
                    </li>
                    <li className="">
                      After clicking on the button of Pay Now, Thank You window
                      will open up on your screen, if you have any document or
                      any reference number put it there
                    </li>
                  </ul>

                  <div class="form-container" enctype="multipart/form-data">
                    <div class="upload-files-container">
                      <h3 style={{ margin: "0px" }}>Upload Your Files</h3>
                      <p
                        style={{
                          margin: "0px",
                          marginTop: "10px",
                          marginBottom: "20px",
                          fontSize: "13px",
                          color: "#7E7E7E",
                        }}
                      >
                        File should be in Image/Pdf
                      </p>
                      <div class="drag-file-area">
                        <span class="material-icons-outlined upload-icon">
                          {" "}
                          <img src={UploadimgN} alt="upload" />{" "}
                        </span>
                        {/* <h3 class="dynamic-message"> Drag & drop any file here </h3> */}
                        <label class="label">
                          {" "}
                          <input
                            style={{ width: "100%" }}
                            type="file"
                            class="default-file-input default-file-input81"
                          />{" "}
                          <span class="browse-files-text">Upload</span>{" "}
                        </label>
                      </div>
                      <span class="cannot-upload-message">
                        {" "}
                        <span class="material-icons-outlined">error</span>{" "}
                        Please select a file first{" "}
                        <span class="material-icons-outlined cancel-alert-button">
                          cancel
                        </span>{" "}
                      </span>
                      <div class="file-block">
                        <div class="file-info">
                          {" "}
                          <span class="material-icons-outlined file-icon">
                            description
                          </span>{" "}
                          <span class="file-name"> </span> |{" "}
                          <span class="file-size"> </span>{" "}
                        </div>
                        <span class="material-icons remove-file-icon">
                          delete
                        </span>
                        <div class="progress-bar"> </div>
                      </div>
                      {/* <button type="button" class="upload-button"> Upload </button> */}
                    </div>
                  </div>
                  <div className="paymentRefrenceNum">
                    <h3>Payment Reference Number*</h3>
                    <div className="refrenceboxSec">
                      <input
                        type="text"
                        placeholder="enter cheque/DD/transaction reference number"
                        onInput={(e) => setReference_number(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                disabled={loading || isWallet}
                style={{
                  cursor: loading || isWallet ? "not-allowed" : "pointer",
                }}
                title={loading || (isWallet && "Partial payment not available")}
                id="CheckOutPayNowBTNTrans"
                className="pay-btn mt-4 CheckOutPayNowBTN"
              >
                <Link
                  disabled={loading || isWallet}
                  style={{
                    cursor: loading || isWallet ? "not-allowed" : "pointer",
                  }}
                  title={
                    loading || (isWallet && "Partial payment not available")
                  }
                  onClick={initiateCheckout}
                >
                  Pay Now
                </Link>
              </button>
              <button
                id="CheckOutPayNowBTNOnline"
                disabled={true}
                style={{ cursor: "not-allowed" }}
                className="pay-btn mt-4 CheckOutPayNowBTN"
              >
                <Link
                  style={{ cursor: "not-allowed" }}
                  onClick={initiateCheckout}
                >
                  Pay Now
                </Link>
              </button>
            </div>
            <div className="rt-container">
              <div className="product_info82">
                <div
                  class="big-bold-heading tc"
                  style={{ padding: "15px", textAlign: "left" }}
                >
                  Product Information
                </div>
                {data && (
                  <div className="cartCardOuter">
                    <div className="cardProImg">
                      <img
                        src={data?.product?.url}
                        alt={data?.product?.name}
                        style={{ width: "100%", height: "auto" }}
                        onError={(e) => {
                          e.target.src = "/no-image.png";
                        }}
                      />
                    </div>
                    <div className="cardProInfo">
                      <p className="text-capitalize">{data?.product.name}</p>
                      <div className="cartCardItem text-capitalize">
                        <p>Seller : {data?.seller?.vendor.company}</p>
                      </div>
                      <div className="cartCardItem text-capitalize">
                        <p>City : {data?.seller?.vendor.city}</p>
                      </div>
                      <div className="cartCardItem">
                        <p>Qty : {data && data?.quantity}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="payment-summary-box w-100">
                <div
                  className="big-bold-heading tc"
                  style={{ marginBottom: "50px", textAlign: "left" }}
                >
                  Payment Summary
                </div>
                <div className="blue-box">
                  <div className="df jcb cart-help-p">
                    <div className="bold-heading bold-heading88">
                      Price (INR)
                    </div>
                    <p className="">
                      {data && formatter.format(data?.sub_total)}
                    </p>
                  </div>
                  <div className="df jcb cart-help-p">
                    <div className="bold-heading bold-heading88">GST (INR)</div>
                    <p className="">
                      {data && formatter.format(data?.total - data?.sub_total)}
                    </p>
                  </div>
                  <div className="df jcb cart-help-p">
                    <div className="bold-heading bold-heading88">
                      Logistics Price (INR)
                    </div>
                    <p className="">{summary && summary.logistic}</p>
                  </div>
                  <div className="df jcb cart-help-p">
                    <div className="bold-heading bold-heading88">
                      Total Price (INR)
                    </div>
                    <p className="">{data && formatter.format(data?.total)}</p>
                  </div>
                  {/* <div className="df jcb cart-help-p">
                  <div className="bold-heading bold-heading88">
                    Logistics GST (INR)
                  </div>
                  <p className="">1,252.00</p>
                        </div>*/}
                </div>
              </div>
              {isWallet && (
                <div className="p-2">
                  <button className="pay-btn mt-4 CheckOutPayNowBTN">
                    <Link onClick={initiateCheckout}>Continue with Wallet</Link>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default CheckoutPending;
