import { NavbarAuth } from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useContext, useState } from "react";
import { RfqContext } from "../../../context/RfqContext";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import { useEffect } from "react";
import { toasterUpdate } from "../../../utils/toasterUpdate";
const RFQStep1 = () => {
  const navigate = useNavigate();
  const { rfq, setRfq } = useContext(RfqContext);
  const today = new Date().toISOString().split("T")[0];
  const [credit, setCredit] = useState(false);
  const [cycleModal, setCycleModal] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const product = searchParams.get("product");

  const [paymentBased, setPaymentBased] = useState({
    placement_day: 0,
    placement_amount: 0,
    fabrication_day: 0,
    fabrication_amount: 0,
    dispatched_day: 0,
    dispatched_amount: 0,
    qc_day: 0,
    qc_amount: 0,
  });
  const [cycleBased, setCycleBased] = useState([
    { days: 15, amount: "" },
    { days: 30, amount: "" },
    { days: 45, amount: "" },
    { days: 60, amount: "" },
    { days: 90, amount: "" },
  ]);

  function updateAmounts(index, newAmount) {
    setCycleBased((prevNumbers) => {
      const updatedNumbers = [...prevNumbers];
      updatedNumbers[index].amount = Number(newAmount);
      return updatedNumbers;
    });
  }

  const createRfq = async (data) => {
    const toastID = toast.loading("Processing...");

    const token = localStorage.getItem("token_web");

    const response = await fetch(`${baseUrl}/rfq`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ ...data }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    toast.dismiss(toastID);

    navigate(
      `/rfqstep2/${result.data.rfq_id}?id=${result.data.id}&product=${
        product || ""
      }`
    );
  };

  const submitRfq = (e) => {
    e.preventDefault();

    if (credit) {
      if (rfq.milestone == "order status based") {
        setPaymentModal(true);
      } else {
        setCycleModal(true);
      }
      return;
    }

    createRfq(rfq);
  };

  const validateOrderBasedMethod = () => {
    const {
      placement_day,
      fabrication_day,
      dispatched_day,
      qc_day,
      placement_amount,
      fabrication_amount,
      dispatched_amount,
      qc_amount,
    } = paymentBased;

    if (
      Number(placement_day) +
        Number(fabrication_day) +
        Number(dispatched_day) +
        Number(qc_day) !=
      90
    ) {
      toast.error(`Days should not greater or less than 90`);
      return false;
    }

    if (
      Number(placement_amount) +
        Number(fabrication_amount) +
        Number(dispatched_amount) +
        Number(qc_amount) !=
      100
    ) {
      toast.error("Percent total should not greater or less than 100");
      return false;
    }

    return true;
  };

  const validateCycleBasedMethod = () => {
    let total = cycleBased.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.amount;
    }, 0);

    if (total != 100) {
      toast.error("Percent total should not greater or less than 100");
      return false;
    }

    return true;
  };

  const proceedPaymentMilestone = (e) => {
    e.preventDefault();

    const isValid = validateOrderBasedMethod();

    if (!isValid) {
      return false;
    }

    createRfq({
      ...rfq,
      milestone: "order status based",
      payment_based: paymentBased,
    });
  };

  const proceedCycleMilestone = (e) => {
    e.preventDefault();

    const isValid = validateCycleBasedMethod();

    if (!isValid) {
      return false;
    }

    createRfq({
      ...rfq,
      milestone: "payment cycle based",
      cycle_based: cycleBased,
    });
  };

  return (
    <>
      <NavbarAuth />
      <section className="profile_page">
        <h1>RFQ Form</h1>
        <p className="text-center">
          Effortlessly request quotes, simplify procurement. Trusted suppliers,
          competitive offers.
        </p>

        <ul className="summary" style={{ padding: "20px 0" }}>
          <li>Basic Details</li>
          <li>Product Request</li>
          <li>Product Request Details</li>
          <li>Seller Selection</li>
          <li>Review Form</li>
        </ul>
        <form onSubmit={submitRfq}>
          <div className="d-flex">
            <div className="form-control mb-4 p-0">
              <label className="required">RFQ Name</label>
              <input
                type="text"
                required
                maxLength={60}
                name="name"
                onInput={(e) => setRfq({ ...rfq, label: e.target.value })}
              />
            </div>
            <div className="form-control mb-4 p-0">
              <label className="required">RFQ Till Date</label>
              <input
                type="date"
                onInput={(e) => setRfq({ ...rfq, opentill: e.target.value })}
                required
                min={today}
                name="opentill"
              />
            </div>
          </div>
          <div>
            <p className="head">
              Product Price Type <span className="text-danger">*</span>
            </p>
            <div className="order_list">
              <FormControl className="w-100">
                <RadioGroup
                  onChange={(e) => {
                    setRfq({ ...rfq, order_type: e.target.value });
                  }}
                  name="order_type"
                >
                  <div className="d-flex">
                    <div className="single">
                      <h6>Landed Cost</h6>
                      <FormControlLabel
                        value="landed"
                        required
                        control={<Radio required={true} />}
                        label="Choose landed cost for hassle free delivery."
                      />
                    </div>
                    <div className="single">
                      <h6>Ex Factory Cost</h6>
                      <FormControlLabel
                        value="ex_factory"
                        required
                        control={<Radio required={true} />}
                        label="Choose ex factory for transparent logistics pricing."
                      />
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div>
            <p className="head">
              Payment Type <span className="text-danger">*</span>
            </p>
            <div className="order_list">
              <FormControl className="w-100">
                <RadioGroup
                  onChange={(e) => {
                    setCredit(() => e.target.value == "credit");
                    setRfq({ ...rfq, payment_type: e.target.value });
                  }}
                  name="payment_type"
                >
                  <div className="d-flex">
                    <div className="single">
                      <h6>Spot Payment</h6>
                      <FormControlLabel
                        value="spot"
                        control={<Radio required={true} />}
                        label="Choose 'Spot Payment' option for convenient and immediate transactions."
                      />
                    </div>
                    <div className="single">
                      <h6>Credit Payment</h6>
                      <FormControlLabel
                        value="credit"
                        control={<Radio required={true} />}
                        label="Choose credit payment and enjoy liquidity offered from Rasayankart."
                      />
                    </div>
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          {credit && (
            <div>
              <p className="head">
                Payment Details <span className="text-danger">*</span>
              </p>
              <div className="order_list">
                <FormControl className="w-100">
                  <RadioGroup
                    onChange={(e) =>
                      setRfq({ ...rfq, milestone: e.target.value })
                    }
                    name="milestone"
                  >
                    <div className="d-flex">
                      <div className="single">
                        <h6>Order Status Based Milestone</h6>
                        <FormControlLabel
                          value="order status based"
                          required
                          control={<Radio required={true} />}
                          label="Simplify projects with milestone payments, ensuring transparency and control in chemical procurement. Pay as milestones are achieved."
                        />
                      </div>
                      <div className="single">
                        <h6>Payment Cycle Based Milestone</h6>
                        <FormControlLabel
                          value="payment cycle based"
                          required
                          control={<Radio required={true} />}
                          label="Customize your payment cycles to suit your needs. Smooth financial management for your chemical RFQs with predetermined stage-based payments."
                        />
                      </div>
                    </div>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          )}
          <div className="" style={{ margin: "0 0 auto auto", width: "50%" }}>
            <button className="cancel">Cancel</button>
            <button className="continue" type="submit">
              Continue
            </button>
          </div>
        </form>
      </section>

      <div className={paymentModal ? "popupbox active-popup" : "popupbox"}>
        <div className="popup-overlay"></div>
        <div className="popup-content" style={{ width: "fit-content" }}>
          <form
            onSubmit={proceedPaymentMilestone}
            style={{
              minHeight: "451px",
              padding: "30px",
              backgroundColor: "#fff",
            }}
          >
            <CloseIcon
              className="popup-i"
              onClick={() => setPaymentModal(!paymentModal)}
            />
            <h1>Payment Milestone</h1>

            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <label className="label_title">Order Accepted</label>

                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Pay within (days)</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          placement_day: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Percentage</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          placement_amount: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <label className="label_title">Production</label>

                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Pay within (days)</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          fabrication_day: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Percentage</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          fabrication_amount: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <label className="label_title">Dispatch</label>

                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Pay within (days)</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          dispatched_day: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Percentage</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          dispatched_amount: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <label className="label_title">QC Accepted</label>

                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Pay within (days)</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          qc_day: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Percentage</label>
                    <input
                      type="number"
                      required
                      onInput={(e) =>
                        setPaymentBased({
                          ...paymentBased,
                          qc_amount: Number(e.target.value),
                        })
                      }
                      id="address_line_1"
                      name="address_line_1"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="contbtn"
              style={{ width: "90%", margin: "0px auto", marginTop: "16px" }}
            >
              Continue
            </button>
          </form>
        </div>
      </div>

      <div className={cycleModal ? "popupbox active-popup" : "popupbox"}>
        <div className="popup-overlay"></div>
        <div className="popup-content" style={{ width: "fit-content" }}>
          <form
            onSubmit={proceedCycleMilestone}
            style={{
              minHeight: "451px",
              padding: "30px",
              backgroundColor: "#fff",
            }}
          >
            <CloseIcon
              className="popup-i"
              onClick={() => setCycleModal(!cycleModal)}
            />
            <h1>Cycle Based Milestone</h1>

            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label>Days</label>
                    <input
                      type="number"
                      required
                      disabled
                      value={15}
                      name="days[]"
                    />
                  </div>
                  <div className="form-group">
                    <label>Percentage</label>
                    <input
                      type="number"
                      required
                      value={cycleBased[0].amount}
                      onInput={(e) => {
                        updateAmounts(0, e.target.value);
                      }}
                      name="amount[]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label>Days</label>
                    <input
                      type="number"
                      required
                      disabled
                      value={30}
                      name="days[]"
                    />
                  </div>
                  <div className="form-group">
                    <label>Percentage</label>
                    <input
                      type="number"
                      required
                      value={cycleBased[1].amount}
                      onInput={(e) => {
                        updateAmounts(1, e.target.value);
                      }}
                      name="amount[]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label>Days</label>
                    <input
                      type="number"
                      required
                      disabled
                      value={45}
                      name="days[]"
                    />
                  </div>
                  <div className="form-group">
                    <label>Percentage</label>
                    <input
                      type="number"
                      required
                      value={cycleBased[2].amount}
                      onInput={(e) => {
                        updateAmounts(2, e.target.value);
                      }}
                      name="amount[]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label>Days</label>
                    <input
                      type="number"
                      required
                      disabled
                      value={60}
                      name="days[]"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address_line_1">Percentage</label>
                    <input
                      type="number"
                      required
                      value={cycleBased[3].amount}
                      onInput={(e) => {
                        updateAmounts(3, e.target.value);
                      }}
                      name="amount[]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-address-popup-div">
              <div className={"payment_milestone_flex"}>
                <div className={"payment_milestone_flex_inputs"}>
                  <div className="form-group">
                    <label>Days</label>
                    <input
                      type="number"
                      required
                      disabled
                      value={90}
                      name="days[]"
                    />
                  </div>
                  <div className="form-group">
                    <label>Percentage</label>
                    <input
                      type="number"
                      required
                      value={cycleBased[4].amount}
                      onInput={(e) => {
                        updateAmounts(4, e.target.value);
                      }}
                      name="amount[]"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="contbtn"
              style={{ width: "90%", margin: "0px auto", marginTop: "16px" }}
            >
              Continue
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default RFQStep1;
