import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import { Add, Clear, Edit, Upload } from "@mui/icons-material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Link, useParams } from "react-router-dom";
import * as React from "react";
import Vec1 from "../../../assets/Vector.png";
import Vec2 from "../../../assets/Vector1.png";
import Vec3 from "../../../assets/Vector2.png";
import Vec4 from "../../../assets/Vector3.png";
import qc from "../../../assets/qc.png";
import { toast } from "react-toastify";
import { baseUrl } from "../../../utils/baseUrl";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import { useState } from "react";
import { useRef } from "react";
import formatter from "../../../utils/formatter";
import Moment from "react-moment";

import "../../dashboard/Buyers/bhd.css";
import Greeting from "../../../component/Greeting";

const OrderDetail = () => {
  const [data, setData] = useState({});
  const [attach, setAttach] = useState("");

  const [orderStatus, setOrderStatus] = useState("");
  const logRef = useRef(null);
  const statusRef = useRef(null);

  const [status, setStatus] = useState({
    order: false,
    fabrication: false,
    transit: false,
    delivered: false,
    qc: false,
  });

  const { id } = useParams();

  const attachRef = useRef();

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Loading...");
    const response = await fetch(`${baseUrl}/order/${id}/show`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    setData(() => result.data);

    setStatus((prev) => {
      switch (result.data.order_status) {
        case "order accepted":
          return { ...prev, order: true };
          break;
        case "in fabrication":
          return { ...prev, order: true, fabrication: true };
          break;
        case "in transit":
          return { ...prev, order: true, fabrication: true, transit: true };
          break;

        case "delivered":
          return {
            ...prev,
            order: true,
            fabrication: true,
            transit: true,
            delivered: true,
          };
          break;

        case "qc accepted":
          return {
            ...prev,
            order: true,
            fabrication: true,
            transit: true,
            delivered: true,
            qc: true,
          };
          break;
        default:
          return { ...prev };
          break;
      }
    });
  };

  const attachApi = async () => {
    const token = localStorage.getItem("token_web");
    const toastID = toast.loading("Loading...");

    const formData = new FormData();
    formData.append("order_id", id);
    formData.append("role", "buyer");
    formData.append("file", attach);

    const response = await fetch(`${baseUrl}/order/attachment`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    fetchApi();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpeg") ||
      (file && file.type === "image/png") ||
      (file && file.type === "image/webp") ||
      (file && file.type === "image/jpg") ||
      (file && file.type === "application/pdf")
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 400000) {
          setAttach("");
          attachRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 400kb."
          );
        } else {
          setAttach(file);
        }
      };
    } else {
      setAttach("");
      attachRef.current.value = null;
      toast.error("Please select a valid image or pdf format.");
    }
  };

  const logApi = async () => {
    const token = localStorage.getItem("token_web");

    const toastID = toast.loading("Updating...");
    const response = await fetch(`${baseUrl}/order/${id}/status`, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        order_status: statusRef.current.value,
        log: logRef.current.value,
      }),
    });

    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }
    logRef.current.value = "";
    toasterUpdate(toastID, result.message, "success");

    fetchApi();
  };

  React.useEffect(() => {
    fetchApi();
  }, []);

  return (
    <>
      <section className="buyers-section">
        <NavbarAuth />
        <div className="buyers-section-div">
          <div className="bsd-left">
            {/* <BuyersSidebar n={7} /> */}
            <BuyersSidebar />
          </div>
          <div className="bsd-right oder-detail profile_page">
          <Greeting />

            <div className="delivery-status-box">
              <strong className="bhd-sub-heading">Delivery Status</strong>
              <div className="delivery-status">
                <div className="ds-box">
                  <div
                    className="ds-box-img"
                    style={{
                      background: status.order && "#0E9E01",
                    }}
                  >
                    <img src={Vec1} alt="" />
                  </div>
                  <div>
                    <div className="ds-heading">Order Accepted</div>
                    {/*<div className="ds-date">28/10/2022</div>*/}
                  </div>
                </div>
                <div
                  className="connector acive-connector"
                  style={{
                    background: status.order && "#0E9E01",
                  }}
                ></div>
                <div className="ds-box">
                  <div
                    className="ds-box-img"
                    style={{
                      background: status.fabrication && "#0E9E01",
                    }}
                  >
                    <img src={Vec2} alt="" />
                  </div>
                  <div>
                    <div className="ds-heading">In Production</div>
                    {/*<div className="ds-date">28/10/2022</div>*/}
                  </div>
                </div>
                <div
                  className="connector"
                  style={{
                    background: status.fabrication && "#0E9E01",
                  }}
                ></div>
                <div className="ds-box">
                  <div
                    className="ds-box-img"
                    style={{
                      background: status.transit && "#0E9E01",
                    }}
                  >
                    <img src={Vec3} alt="" />
                  </div>
                  <div>
                    <div className="ds-heading">In Transit</div>
                    <div className="ds-date">-</div>
                  </div>
                </div>
                <div
                  className="connector"
                  style={{
                    background: status.transit && "#0E9E01",
                  }}
                ></div>
                <div className="ds-box">
                  <div
                    className="ds-box-img"
                    style={{
                      background: status.delivered && "#0E9E01",
                    }}
                  >
                    <img src={Vec4} alt="" />
                  </div>
                  <div>
                    <div className="ds-heading">Delivered</div>
                    <div className="ds-date">-</div>
                  </div>
                </div>
                <div
                  className="connector"
                  style={{
                    background: status.delivered && "#0E9E01",
                  }}
                ></div>
                <div className="ds-box">
                  <div
                    className="ds-box-img"
                    style={{
                      background: status.qc && "#0E9E01",
                    }}
                  >
                    <img src={qc} alt="" />
                  </div>
                  <div>
                    <div className="ds-heading">Qc Accepted</div>
                    <div className="ds-date">-</div>
                  </div>
                </div>
              </div>
              <div className="col-12 text-end">
                <button
                  className="btn btn-primary btn-theme"
                  data-bs-toggle="modal"
                  disabled={!status.delivered}
                  title="Available after order status updates to delivered"
                  data-bs-target="#exampleModal"
                >
                  Update Status
                </button>
              </div>

              {status.delivered && (
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div
                        className="modal-header"
                        style={{ border: "none", paddingBottom: "0px" }}
                      >
                        <h1 className="modal-title fs-5" id="exampleModalLabel">
                          Status Update
                        </h1>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div className="mb-3">
                          <label
                            for="exampleFormControlInput1"
                            className="form-label"
                          >
                            Status
                          </label>
                          <select
                            className="form-select"
                            ref={statusRef}
                            onChange={(e) => setOrderStatus(e.target.value)}
                            aria-label="Default select example"
                          >
                            <option
                              selected={data.order_status == "qc accepted"}
                              value="qc accepted"
                            >
                              Qc Accepted
                            </option>
                          </select>
                        </div>
                        <div className="mb-3">
                          <label
                            for="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Logs
                          </label>
                          <textarea
                            ref={logRef}
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                      <div
                        className="modal-footer"
                        style={{ border: "none", paddingTop: "0px" }}
                      >
                        <button
                          type="button"
                          onClick={logApi}
                          className="btn btn-primary theme-btn"
                        >
                          Update Status
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="log-box">
                <strong>Logs</strong>

                <table style={{ width: "100%" }}>
                  {data.logs &&
                    data.logs.map((item, index) => {
                      return (
                        <tr>
                          <td className="text-capitalize">
                            <span>{item.log}</span>
                          </td>
                          <td>
                            <Moment format="ll - hh:mm a">
                              {item.created_at}
                            </Moment>
                          </td>
                        </tr>
                      );
                    })}
                </table>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center btn btn p-0">
              <strong className="bhd-sub-heading">Payment Details</strong>

              {!data.payment_mode && data.payment_method !== "milestone" && (
                <Link to={`/payment/${data?.id}`} className="btn btn-primary">
                  Pay Now
                </Link>
              )}
            </div>
            <div className="">
              <div className="account">
                <div className="detail">
                  <label>Order Type</label>{" "}
                  <span className="text-capitalize">{data?.order_type}</span>
                  {/*<Link>View</Link>*/}
                </div>
                <div className="detail">
                  <label>Product Price Type</label>{" "}
                  <span className="text-capitalize">
                    {data.delivery_type == "pickup"
                      ? "Ex-Factory cost"
                      : "Landed cost"}
                  </span>
                </div>
              </div>
              <div className="account">
                <div className="detail">
                  <label> Payment Type</label>{" "}
                  <span className="text-capitalize">
                    {" "}
                    {data.payment_method != "milestone"
                      ? "Spot Payment"
                      : "Milestone"}
                  </span>
                </div>

                <div className="detail">
                  <label>Payment Mode</label>{" "}
                  <span className="text-capitalize">{data.payment_mode}</span>
                </div>
              </div>
              <div className="account">
                {data.payment_mode && (
                  <div className="detail">
                    <label>Payment ID</label>
                    <span>{data?.payment_reference}</span>
                  </div>
                )}
              </div>
              <div className="account">
                {data?.order_based && (
                  <>
                    <table className="table table-bordered w-50 mt-2">
                      <caption>Order Status Based Milestone</caption>
                      <tr>
                        <th>Label</th>
                        <th>Days</th>
                        <th>Amount</th>
                        <th>Payment Status</th>
                      </tr>

                      <tr>
                        <td>Placement</td>
                        <td>{data.order_based.placement_day}</td>
                        <td>{data.order_based.placement_amount}%</td>
                        <td className="text-capitalize">
                          {data.order_based.status}
                        </td>
                      </tr>
                      <tr>
                        <td>Production</td>
                        <td>{data.order_based.fabrication_day}</td>
                        <td>{data.order_based.fabrication_amount}%</td>
                        <td className="text-capitalize">
                          {data.order_based.status}
                        </td>
                      </tr>
                      <tr>
                        <td>Dispatched</td>
                        <td>{data.order_based.dispatched_day}</td>
                        <td>{data.order_based.dispatched_amount}%</td>
                        <td className="text-capitalize">
                          {data.order_based.status}
                        </td>
                      </tr>
                      <tr>
                        <td>QC</td>
                        <td>{data.order_based.qc_day}</td>
                        <td>{data.order_based.qc_amount}%</td>
                        <td className="text-capitalize">
                          {data.order_based.status}
                        </td>
                      </tr>
                    </table>
                  </>
                )}

                {data?.cycle_based && data?.cycle_based?.length > 0 && (
                  <>
                    <table className="table table-bordered w-50 mt-2">
                      <caption>Cycle Based Milestone</caption>
                      <tr>
                        <th>Day</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>

                      {data.cycle_based.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.days}</td>
                            <td>{item.amount}%</td>
                            <td className="text-capitalize">{item.status}</td>
                          </tr>
                        );
                      })}
                    </table>
                  </>
                )}
              </div>
            </div>

            <strong className="bhd-sub-heading">Account Details </strong>
            <div className="account">
              <div className="detail">
                <label> Order ID </label>{" "}
                <span className="text-capitalize">{data?.order_id}</span>
              </div>
              <div className="detail">
                <label> Shipping Details </label>{" "}
                <span className="text-capitalize">
                  {data?.delivery_type == "pickup"
                    ? "Ex-Factory cost"
                    : "Landed cost"}
                </span>
              </div>
            </div>
            <div className="">
              <div className="account">
                <div className="detail">
                  <label> Company Name</label>{" "}
                  <span className="text-capitalize">
                    {data.seller?.vendor.company}
                  </span>
                </div>
                <div className="detail">
                  <label> Payment Method </label>{" "}
                  <span className="text-capitalize">{data.payment_mode}</span>
                </div>
              </div>
              <div className="account">
                <div className="detail">
                  <label> Order Date </label>{" "}
                  <span>
                    <Moment format="D-MMMM-Y hh:mm a">{data.created_at}</Moment>
                  </span>
                </div>
                <div className="detail">
                  <label> Delivery (ETA) </label> <span>null</span>
                </div>
              </div>
            </div>
            <strong className="bhd-sub-heading">Address Details </strong>

            <div className="address">
              <div className="shipp">
                Shipping Address <br /> <br />{" "}
                <span className="text-capitalize">
                  {data.shipping_address} {data.shipping_city},{" "}
                  {data.shipping_state}, {data.shipping_country},{" "}
                  {data.shipping_zip}
                </span>
              </div>
              <div className="shipp">
                Billing Address <br /> <br />{" "}
                <span className="text-capitalize">
                  {data.shipping_address} {data.billing_city},{" "}
                  {data.billing_state}, {data.billing_country},{" "}
                  {data.billing_zip}
                </span>
              </div>

              {data.delivery_type == "pickup" && (
                <div className="shipp">
                  Pickup Address <br /> <br />{" "}
                  <span>
                    {data.deliver_from_address} {data.deliver_from_city},{" "}
                    {data.deliver_from_state}, {data.deliver_from_country},{" "}
                    {data.deliver_from_zip}
                  </span>
                </div>
              )}
            </div>

            <div className="bhdBorder">
              <strong className="bhd-sub-heading">Items Ordered</strong>
              <div className="bhd-orders">
                <table className="seller-price-table bhd-table">
                  <tr className="tablerow-head">
                    <th className="table-head">Product</th>
                    <th className="table-head">Unit Price</th>
                    <th className="table-head">Quantity</th>
                    <th className="table-head">Sub Total</th>
                  </tr>
                  <tr className="tablerow-data">
                    <td className="table-data text-capitalize">
                      {data.product?.name}
                    </td>
                    <td className="table-data">
                      {formatter.format(data.price)}
                    </td>
                    <td className="table-data">{data.quantity}</td>
                    <td className="table-data">
                      {formatter.format(data.sub_total)}
                    </td>
                  </tr>
                </table>
              </div>

              <div className="bhd-total">
                <div className="total-div">
                  <div className="total-div-left">Discount</div>
                  <div className="total-div-right">₹{data.discount}</div>
                </div>
                <div className="total-div">
                  <div className="total-div-left">Landed Cost</div>
                  <div className="total-div-right">₹{data.shipping_charge}</div>
                </div>
                <div className="total-div">
                  <div className="total-div-left">GST</div>
                  <div className="total-div-right">
                    {formatter.format(data.total - data.sub_total)}
                  </div>
                </div>
                <div className="total-div">
                  <div className="total-div-left">Total</div>
                  <div className="total-div-right">
                    {formatter.format(data.total)}
                  </div>
                </div>
              </div>
            </div>

            {data?.attachment?.length > 0 && (
              <div className="mt-3">
                <h5>Attachments</h5>
                <div className="d-flex mt-4">
                  {data?.attachment?.map((el, index) => {
                    return (
                      <div className="p-4">
                        <a
                          href={el.url}
                          className="border p-4"
                          target="_blank"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <PictureAsPdfIcon fontSize="small" />
                          Download
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            <div className="docum form-control mb-4">
              <label>Documents</label>
              <input
                type="file"
                name="url"
                ref={attachRef}
                onChange={handleFileChange}
              />
              <Upload className="icon" />
            </div>
            <div
              className="form-control"
              style={{
                margin: "0 0 auto auto",
                width: "50%",
                textAlign: "right",
              }}
            >
              {/* <button className="cancel">Cancel</button> */}
              <button className="continue" onClick={attachApi}>
                Send
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderDetail;
