import React from 'react'
import './mob.css';
import { Link } from 'react-router-dom'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MobInputs from './MobInput';
import Navbar from '../../landing_page/Navbar';
import Footer from '../../landing_page/Footer';


function MobDet1(props) {
    return (
        <>
        <Navbar />
        <div className="moblogin-container">
            <Link to={'/motp'} className="back-arrow"><KeyboardBackspaceIcon /></Link>
            <div className="mob-heading">Enter Details To sign up</div>

            <div className="progess-line">
                <div className="forget-b">Fill up the form</div>
                <div className="progress-container"><span className="progess"></span></div>
            </div>

            <form>
                <MobInputs title='Name' type='text' name='new_user_email' />
                <MobInputs title='Email id' type='email' name='new_user_number' />
                <MobInputs title='Password' type='password' name='new_user_password' />
            
                <Link to={'/mdet2'} className='conbtn-g mob-btn'>Continue</Link>
            </form>

        </div>
        <Footer />
        </>
    )
}

export default MobDet1;