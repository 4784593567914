import React from 'react';
import Footer from '../../landing_page/Footer';
import Navbar from '../Components/Navbar/Navbar';
import './approvepage.css';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

function ApproveInputs({ arr }) {
  return (
    arr.map((arr, index) => (
      <div key={index} className="approve-input-c">
        <label htmlFor={arr.id}>{arr.label}</label>
        {arr.spl ? <div className='label-2-g'>{arr.spl}</div> : ''}
        <input type="text" id={arr.id} name={arr.id} />
      </div>
    ))
  );
}

function ApprovePage() {

  let content = [
    {
      label: 'Per Pieces Product Price',
      spl: 'Excl of GST',
      id: 'poroprice'
    },
    {
      label: 'Quantity',
      spl: 'Example : 30',
      id: 'quantity'
    },
    {
      label: 'Product GST(%)',
      id: 'productgst'
    },
    {
      label: 'Packaging Pricing(INR)',
      id: 'packagingprice'
    },
    {
      label: 'Packaging  GST(%)',
      id: 'packaginggst'
    },
    {
      label: 'Logistics Pricing(INR)',
      id: 'logisticsprice'
    },
    {
      label: 'Logistics GST(%)',
      id: 'logisticsgst'
    },
  ]

  return (
    <section className="approve-section">
      <Navbar />
      <div className="approve-div">
        <strong>Response RFQ</strong>
        <form className="approve-form">
          <ApproveInputs arr={content} />
          <div className="approve-milestone">
            <h1>Milestone</h1>

            <div className="input-heading-container">
              <div className="input-heading">Date</div>
              <div className="input-heading">Percentage</div>
              <div className="input-heading">Payment</div>
            </div>
            <div className="input-rows">
              <input type="text" className="ir1" />
              <input type="text" className="ir1" />
              <input type="text" className="ir1" />
            </div>
            <div className="input-rows">
              <input type="text" className="ir1" />
              <input type="text" className="ir1" />
              <input type="text" className="ir1" />
            </div>
            <div className="input-rows">
              <input type="text" className="ir1" />
              <input type="text" className="ir1" />
              <input type="text" className="ir1" />
            </div>
            <button className='c-p-i-b'>Add More <AddIcon /></button>
          </div>
        </form>

        <div className="btn-con">
          <div className="backbtn-g">Back</div>
          <Link to='/brd' className="conbtn-g">Continue</Link>
        </div>
      </div>
      <Footer />
    </section>
  )
}

export default ApprovePage