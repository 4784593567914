import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import { Clear, Tune } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import Imginfo from "../../../assets/Information.svg";
import SortIcon from "@mui/icons-material/Sort";
import { Checkbox } from "@mui/material";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { RfqContext } from "../../../context/RfqContext";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";

const FormStep4 = () => {
  const navigate = useNavigate();
  const { rfq_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [selectedCatalogs, setSelectedCatalogs] = useState([]);
  const [shownProducts, setShownProducts] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const token = localStorage.getItem("token_web");

  const fetchApi = async () => {
    const response = await fetch(`${baseUrl}/rfq/${id}/selectSeller`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();
    if (result.error) {
      toast.error(result.message);
      return;
    }
    setSelectedCatalogs(result?.data?.catalogs || []);
    setSellers(() => result?.data?.sellers || []);
    setSelectedSellers(() => result?.data?.sellers || []);
  };

  const handleSellerSelection = (seller) => {
    if (selectedSellers.includes(seller)) {
      setSelectedSellers(selectedSellers.filter((item) => item !== seller));
    } else {
      setSelectedSellers([...selectedSellers, seller]);
    }
  };

  const handleSelectAllToggle = (bool) => {
    setSelectAll(bool);
    setSelectedSellers(bool ? [] : sellers.map((seller) => seller));
  };

  useEffect(() => {
    fetchApi();
  }, []);

  const submitRfq = async (e) => {
    e.preventDefault();

    if (selectedSellers.length === 0) {
      toast.error("Select atleast one seller");
      return;
    }

    const productDetail = selectedSellers.map((item) => {
      return {
        vendor_id: item.vendor_id,
        rfq_id: id,
        rfq_product_id: item.catalog_id.map((val) => val),
      };
    });

    const response = await fetch(`${baseUrl}/rfq/${id}/selectSeller`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(productDetail),
    });

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    toast.success(result.message);

    navigate(`/rfqstep5/${rfq_id}?id=${id}`);
  };

  function back() {
    navigate(`/rfqstep3/${rfq_id}?id=${id}`);
  }

  const showProductsMatched = (products) => {
    setShownProducts(products);
  };

  return (
    <>
      <NavbarAuth />
      <section className="profile_page">
        <h1>RFQ Form</h1>
        <p className="text-center">
          Select preferred vendors from our extensive list for your RFQ.
          Simplify the selection process, connect with trusted suppliers for
          your chemical needs.
        </p>

        <ul className="summary step2 step3 step4" style={{ padding: "20px 0" }}>
          <li>Basic Details</li>
          <li>Product Request</li>
          <li>Product Request Details</li>
          <li>Seller Selection</li>
          <li>Review Form</li>
        </ul>
        <form onSubmit={submitRfq}>
          <div className="tags">
            <div
              style={{
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="d-flex"
            >
              <label className="seller">Select Seller</label>
            </div>

            <div style={{ width: "100%" }}>
              <FormControl>
                <RadioGroup defaultValue="other" name="radio-buttons-group">
                  <FormControlLabel
                    value="custom"
                    onClick={() => handleSelectAllToggle(true)}
                    control={<Radio checked={selectAll} />}
                    label="Custom"
                  />
                  <FormControlLabel
                    value="automatic"
                    onClick={() => handleSelectAllToggle(false)}
                    control={<Radio checked={!selectAll} />}
                    label="Automatic"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div style={{ position: "relative", paddingBottom: "50px" }}>
            <div className="table-c ">
              <table className="seller-price-table pdt">
                <tr className="tablerow-head">
                  <th></th>
                  <th>Seller</th>
                  <th className="infoic ">City</th>
                  <th className="infoic">Membership </th>
                  <th className="infoic">Product Available</th>
                  <th className="infoic">Action</th>
                </tr>

                {sellers &&
                  sellers.map((item, index) => {
                    return (
                      <tr className="tablerow-data" key={item.vendor_id}>
                        <td className="table-data inp-td">
                          {selectAll && (
                            <Checkbox
                              onClick={() => handleSellerSelection(item)}
                            />
                          )}
                        </td>
                        <td className="table-data text-capitalize">
                          {item.vendor_name}
                        </td>
                        <td className="table-data text-capitalize text-nowrap">
                          {item?.city} / {item?.state}
                        </td>
                        <td
                          className={item.membership}
                          style={{ textAlign: "center", width: "100px" }}
                        >
                          <span>{item.membership}</span>
                        </td>
                        <td
                          className="table-data text-capitalize"
                          style={{ textAlign: "center" }}
                        >
                          {item.num_matched_products} / {item.total_products}
                        </td>
                        <td className="table-data text-capitalize">
                          <button
                            className="btn text-primary"
                            type="button"
                            onClick={() =>
                              showProductsMatched({
                                products: item.products,
                                seller: item.vendor_name,
                              })
                            }
                          >
                            View Products
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </table>
            </div>
          </div>

          <div
            className="form-control"
            style={{ margin: "0 0 auto auto", width: "50%" }}
          >
            <button className="cancel" type="button" onClick={back}>
              Cancel
            </button>
            <button className="continue" type="submit">
              Continue
            </button>
          </div>
        </form>
        {shownProducts &&
          shownProducts.products &&
          shownProducts.products.length > 0 && (
            <form className="mt-4">
              <div className="tags">
                <div
                  style={{
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="d-flex"
                >
                  <label className="seller fs-5">
                    Matched Products Displayed for{" "}
                    <span className="text-capitalize">
                      {shownProducts.seller}
                    </span>
                  </label>

                  <button
                    className="btn text-primary"
                    type="button"
                    onClick={() => setShownProducts([])}
                  >
                    Close
                  </button>
                </div>
              </div>

              {selectedCatalogs &&
                selectedCatalogs.length > 0 &&
                selectedCatalogs.map((catalog, index) => (
                  <div
                    style={{ position: "relative", paddingBottom: "50px" }}
                    key={catalog.id}
                  >
                    <div className="table-c border">
                      <h5 className="text-capitalize fs-5 bg-light my-0 py-3 px-2">
                        {index + 1} {`. ${catalog.name}`}
                      </h5>
                      <table className="seller-price-table pdt">
                        {shownProducts &&
                        shownProducts.products &&
                        shownProducts?.products?.filter(
                          (item) => item.catalogue_id == catalog.id
                        ).length == 0 ? (
                          <p className="ps-2">No Matched Products Found</p>
                        ) : (
                          <tr className="tablerow-head bg-white">
                            <th className="infoic ps-2">Product</th>
                            <th className="infoic text-nowrap product-names">Product Name</th>
                            <th className="infoic text-nowrap">
                              Product Price
                            </th>
                            <th className="infoic qty-names">Unit</th>
                            <th className="infoic text-nowrap">
                              Minimum RFQ Price
                            </th>
                            <th className="infoic text-nowrap">
                              Minimum RFQ Qty
                            </th>
                          </tr>
                        )}

                        {shownProducts &&
                          shownProducts.products &&
                          shownProducts?.products
                            ?.filter((item) => item.catalogue_id == catalog.id)
                            .map((item, index) => {
                              return (
                                <tr className="tablerow-data" key={item.id}>
                                  <td className="text-capitalize pt-4">
                                    <div
                                      style={{
                                        width: "80px",
                                        height: "80px",
                                        position: "relative",
                                      }}
                                    >
                                      <img
                                        src={item.url}
                                        onError={(e) => {
                                          e.target.src = "/no-image.png";
                                        }}
                                        style={{
                                          maxWidth: "100%",
                                          maxHeight: "100%",
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td className="text-capitalize text-wrap product-names">
                                    <Link to={`/product/${item.id}/seller`}>
                                      {item.name}
                                    </Link>
                                  </td>
                                  <td className="text-capitalize text-nowrap">
                                    ₹{item?.max_price || item?.price}
                                  </td>
                                  <td className="text-wrap text-capitalize qty-names">
                                    {item?.unit}
                                  </td>
                                  <td className="text-capitalize">
                                    ₹{item.min_price}
                                  </td>
                                  <td className="text-capitalize">
                                    {item.min_quantity}
                                  </td>
                                </tr>
                              );
                            })}
                      </table>
                    </div>
                  </div>
                ))}
            </form>
          )}
      </section>

      <Footer />
    </>
  );
};

export default FormStep4;
