import './producthistory.css' ;
import CloseIcon from '@mui/icons-material/Close';
import { Link } from 'react-router-dom';
import Product from '../Product/Product';


function ProductHistory(props) {
    return ( 
        <div className="ph-card df ">
            {/* <img src={props.img} alt="" />
            <div className="df fdc">
                <Link to='/product' className="ph-name">Camlin Engineers Blue Pastes 20 ml</Link>
                <div className="df jcb aic ph-detb">
                    <p>Burhani suppliers</p>
                    <p>Chennai</p>
                </div>
                <div className="df jcb aic ph-detb">
                    <div className='ph-price'>₹50</div>
                    <p>20 ml</p>
                </div>
            </div> */}
            <Product img={props.img}/>
            <button className="delete">
                <CloseIcon/>
            </button>
        </div>
     );
}

export default ProductHistory;