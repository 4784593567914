import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import Footer from "../../landing_page/Footer";
import { Clear, Tune } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { RfqContext } from "../../../context/RfqContext";
import { units } from "../../../utils/unitSelector";
import { toasterUpdate } from "../../../utils/toasterUpdate";

const ProductDetailsAdd = ({ item, index, removeProduct }) => {
  return (
    <div className="pdt_Detail py-2">
      <h6 style={{ color: "#000" }} className="text-capitalize">
        <b>{index}.</b>
        {item.catalog ? item.catalog.name : item.name}{" "}
        <span
          style={{ display: "inline-block", marginLeft: "auto" }}
          onClick={() => removeProduct(item)}
        >
          <Clear />
        </span>
      </h6>
    </div>
  );
};

const FormStep2 = () => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);

  const [data, setData] = useState({});
  const { rfq, setRfq } = useContext(RfqContext);
  const { rfq_id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const product = searchParams.get("product");

  const navigate = useNavigate();

  const token = localStorage.getItem("token_web");

  const fetchApi = async () => {
    const toastID = toast.loading("Fetching...");
    const response = await fetch(`${baseUrl}/rfq/${id}/draft`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    if (result.error) {
      toasterUpdate(toastID, result.message, "error");
      return;
    }

    toast.dismiss(toastID);
    setSelectedProducts(() => result.data?.added_product);
  };

  const searchApi = async (search) => {
    const response = await fetch(
      `${baseUrl}/catalog/search/${search}?limit=5`,
      {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    toast.dismiss();
    setProducts(() => result.data);
  };

  const submitRfq = async (e) => {
    e.preventDefault();
    const newSelectedProducts = selectedProducts
      .filter((item) => !item.catalog) // Exclude items with catalog
      .map((item) => ({ catalog_id: item.id }));

    const response = await fetch(`${baseUrl}/rfq/${id}/addProducts`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newSelectedProducts),
    });

    const result = await response.json();

    navigate(`/rfqstep3/${rfq_id}?id=${id}`);
  };

  function back() {
    navigate(`/rfqstep1?id=${id}`);
  }

  const addNewByProductID = async (product) => {
    const response = await fetch(`${baseUrl}/catalog/searchByID/${product}`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const result = await response.json();

    const isMatched = selectedProducts.filter((product) => {
      if (product.catalog && product.catalog.id == result.data.id) {
        return product;
      }

      if (product.id == result.data.id) return product;
    });

    if (isMatched.length > 0) {
      return;
    }

    // searchApi(null);
    setSelectedProducts([...selectedProducts, ...result.data]);
  };

  const addNew = (item) => {
    // searchApi(null);

    if (!item.listed) {
      toast.error("Product is not listed by any seller.");
      return;
    }

    const isMatched = selectedProducts.filter((product) => {
      if (product.catalog && product.catalog.id == item.id) {
        return product;
      }

      if (product.id == item.id) return product;
    });

    if (isMatched.length > 0) {
      toast.error("Product already added.");
      return;
    }

    setSelectedProducts([...selectedProducts, item]);
  };

  const removeProduct = async (item) => {
    if (item.catalog) {
      await (
        await fetch(`${baseUrl}/rfq/${item.id}/remove`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      ).json();
    }

    const newProducts = selectedProducts.filter((val) => item.id !== val.id);
    setSelectedProducts(newProducts);
  };

  useEffect(() => {
    fetchApi();

    if (product) {
      addNewByProductID(product);
    }
  }, []);

  return (
    <>
      <NavbarAuth />
      <section className="profile_page">
        <h1>RFQ Form</h1>
        <p className="text-center">
          Effortlessly submit your RFQ, include desired chemicals. Simplify the
          process, receive competitive quotes from trusted suppliers.
        </p>

        <ul className="summary step2" style={{ padding: "20px 0" }}>
          <li>Basic Details</li>
          <li>Product Request</li>
          <li>Product Request Details</li>
          <li>Seller Selection</li>
          <li>Review Form</li>
        </ul>
        <form onSubmit={submitRfq}>
          <div className="searchPendingItem" style={{ margin: "20px 0" }}>
            <input
              type="text"
              onInput={(e) =>
                searchApi(e.target.value.length > 0 ? e.target.value : null)
              }
              placeholder="Search product & add to your RFQ"
            />
            <span className="search">Search</span>
          </div>

          <div className="" style={{ margin: "20px 0" }}>
            <div className="row">
              {products &&
                products?.length > 0 &&
                products.map((item) => (
                  <div className="col-12 col-md-6 d-flex justify-content-between align-items-center">
                    <p className="text-capitalize">{item.name}</p>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => addNew(item)}
                    >
                      Add
                    </button>
                  </div>
                ))}
            </div>
          </div>

          {selectedProducts &&
            selectedProducts.length > 0 &&
            selectedProducts.map((item, index) => (
              <ProductDetailsAdd
                item={item}
                index={index + 1}
                removeProduct={removeProduct}
              />
            ))}

          <div
            className="form-control"
            style={{ margin: "0 0 auto auto", width: "50%" }}
          >
            <button className="cancel" type="button" onClick={back}>
              Cancel
            </button>
            <button
              className="continue"
              disabled={selectedProducts.length === 0}
              type="submit"
            >
              Continue
            </button>
          </div>
        </form>
      </section>

      <Footer />
    </>
  );
};

export default FormStep2;
