import './done.css';
import DoneImg from '../../../assets/complete.png'
import UploadimgN from '../../../assets/File_fill.png'
import DocumentImg from '../../../assets/Document.png'
import Navbar, { NavbarAuth } from '../Components/Navbar/Navbar';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import Footer from '../../landing_page/Footer';

function Doneslc() {
    return (
        <>
            <section className="place-sec-1">
                <NavbarAuth />
                <div className="checkout-done">
                    <div className="cd-top">
                        <img src={DoneImg} alt=" " />
                        <h1>Thank you your order has been placed</h1>
                        <p> If you have any document or any reference number please put it here</p>
                    </div>




                    <link href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined" rel="stylesheet" />
                    <form class="form-container" enctype='multipart/form-data'>
                        <div class="upload-files-container">
                            <h3 style={{ margin: "0px" }}>Upload Your Files</h3>
                            <p style={{ margin: "0px", marginTop: "10px", marginBottom: "20px", fontSize: "13px", color: "#7E7E7E" }}>File should be in PDF</p>
                            <div class="drag-file-area">
                                <span class="material-icons-outlined upload-icon"> <img src={UploadimgN} alt="upload" /> </span>
                                {/* <h3 class="dynamic-message"> Drag & drop any file here </h3> */}
                                <label class="label">  <input style={{ width: "100%" }} type="file" class="default-file-input default-file-input81" /> <span class="browse-files-text">Drag & drop any file here</span> </label>
                            </div>
                            <span class="cannot-upload-message"> <span class="material-icons-outlined">error</span> Please select a file first <span class="material-icons-outlined cancel-alert-button">cancel</span> </span>
                            <div class="file-block">
                                <div class="file-info"> <span class="material-icons-outlined file-icon">description</span> <span class="file-name"> </span> | <span class="file-size">  </span> </div>
                                <span class="material-icons remove-file-icon">delete</span>
                                <div class="progress-bar"> </div>
                            </div>
                            {/* <button type="button" class="upload-button"> Upload </button> */}
                            <h3 style={{ textAlign: "left", width: "100%", marginBottom: "10px" }}>Uploaded Files</h3>
                            <div style={{ textAlign: "left", width: "100%", display: "flex", alignItems: "center" }}><img style={{ marginRight: "15px" }} src={DocumentImg} /> <span>Check/ DD copy.pdf</span></div>
                        </div>
                    </form>

                    <div className='paymentRefrenceNum'>
                        <h3>Payment Reference Number</h3>
                        <div className='refrenceboxSec'>
                            <input type="text" />
                            <button className='refrencesendBtn'>Send</button>
                        </div>
                        
                    </div>{/* end of paymentRefrenceNum */}




                    <div className="cd-bottom cd-bottom2">
                        <Link className="cd-bottom-c" to='/bh'>Go To  My Orders </Link>
                        <Link className="cd-bottom-c Shop-More" to='/shop'>Shop More</Link>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default Doneslc;