import React, { useState, useEffect } from "react";

import Navbar, { NavbarAuth } from "../Components/Navbar/Navbar";
import BuyersSidebar from "../Components/BuyerSidebar/BuyersSidebar";
import "./buyers.css";
import ProductHistory from "../Components/ProductHistory/ProductHistory";
import Img from "../../../assets/product.png";
import ImgFilter from "../../../assets/arrowDown-color-Filter.png";
import ImgsearchIcon from "../../../assets/search.png";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import { borderRadius } from '@mui/system';
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
// import PriceRangeApi from './priceRange';
import $ from "jquery";
import "jquery-ui-dist/jquery-ui";
import { baseUrl } from "../../../utils/baseUrl";
import { toast } from "react-toastify";
import { toasterUpdate } from "../../../utils/toasterUpdate";
import Pagination from "../../../component/Pagination";
import { useRef } from "react";
import Moment from "react-moment";
import Greeting from "../../../component/Greeting";
// import {useEffect} from "react";

function Support() {
  const [data, setData] = useState([]);
  const [from, setFrom] = useState(1)
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  const [subject, setSubject] = useState("");
  const [query, setQuery] = useState("");
  const [attach, setAttach] = useState("");

  const [search, setSearch] = useState("");

  const attachRef = useRef(null);
  const formRef = useRef(null);
  const closeRef = useRef(null);

  const [value, onChange] = useState(new Date());

  const fetchApi = async () => {
    const token = localStorage.getItem("token_web");
    const response = await fetch(`${baseUrl}/tickets?page=${currentPage}&id=${search}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    const result = await response.json();

    if (result.error) {
      toast.error(result.message);
      return;
    }

    setData(() => result.data.data);
    setFrom(() => result.data.from);
    setLastPage(result.data.last_page);
  };

  const createApi = async (e) => {
    try {
      e.preventDefault();

      const toastID = toast.loading("Creating...");
      const formData = new FormData();

      formData.append("subject", subject);
      formData.append("query", query);
      formData.append("attach", attach);

      const token = localStorage.getItem("token_web");
      const response = await fetch(`${baseUrl}/ticket`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const result = await response.json();

      if (result.error) {
        toasterUpdate(toastID, result.message, "error");
        return;
      }

      formRef.current.reset();
      closeModal();
      fetchApi();
      toasterUpdate(toastID, result.message, "success");
    } catch (error) {
      toast.error("Something Technical Error");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (
      (file && file.type === "image/jpg") ||
      file.type === "image/png" ||
      file.type === "image/webp" ||
      file.type === "application/pdf"
    ) {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        if (file.size > 1000000) {
          setAttach("");
          attachRef.current.value = null;
          toast.error(
            "The file size is too large. Please select a file under 1000kb."
          );
        } else {
          setAttach(file);
        }
      };
    } else {
      setAttach("");
      attachRef.current.value = null;
      toast.error("Please select a valid WebP image format or PDF.");
    }
  };

  function closeModal() {
    closeRef.current.click();
  }

  useEffect((useEffect) => {
    $(".ConfirnFilt").click(function () {
      $(".Allfilterinner").slideDown("fast");
      $(".hideRageSliderDivfix").show();
    });
    $(".hideRageSliderDivfix, .history-btn").click(function () {
      $(".Allfilterinner").slideUp("fast");
      $(".hideRageSliderDivfix").hide();
    });
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchApi();
  }, [search, currentPage]);

  return (
    <section className="buyers-section">
      <NavbarAuth />
      <div className="buyers-section-div">
        <div className="bsd-left">
          <BuyersSidebar n={10} />
        </div>
        <div className="bsd-right bsd-right8247">
          <div className="px-3 pt-3">
            <Greeting />
          </div>

          <div className="Outer-bsd-right8247">
            <div className="searchPendingItem" style={{ marginBottom: "20px" }}>
              <input type="text" onInput={(e) => setSearch(e.target.value)} placeholder="Search By Ticket Id" />
              <img src={ImgsearchIcon} alt="d" />
            </div>
            <div className="hideRageSliderDivfix"></div>
            <div className="">
              <div className="ConfirnFilt">
                <button
                  class="btn btn-light float-end"
                  data-bs-toggle="modal"
                  data-bs-target="#ticketModal"
                >
                  Create Ticket
                </button>
              </div>
            </div>
            <div className="table table-responsive w-100">
              <table className="table table-gray" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th style={{ textAlign: "left" }}>Sr. No.</th>
                    <th style={{ textAlign: "left" }}>Ticket ID</th>
                    <th style={{ textAlign: "left" }}>Date</th>
                    <th style={{ textAlign: "left" }}>Subject</th>
                    <th style={{ textAlign: "left" }}>Status</th>
                    <th style={{ textAlign: "left" }}>
                      Details
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data &&
                    data.map((item, index) => (
                      <tr key={index} style={{ padding: "10px" }}>
                        <td style={{ textAlign: "left" }}>{from + index}</td>
                        <td style={{ textAlign: "left" }} className="fw-bold">#{item.ticket_id}</td>
                        <td style={{ textAlign: "left" }}>
                          <Moment format="D-MMMM-Y hh:mm a">{item.created_at}</Moment>
                        </td>                        <td style={{ textAlign: "left" }}>{item.subject}</td>
                        <td style={{ textAlign: "left" }} className="text-capitalize">{item.status}</td>
                        <td style={{ textAlign: "left", padding: 10 }}>
                          <Link to={`/support/${item.id}/detail`}>View</Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              <Pagination
                handlePageChange={handlePageChange}
                lastPage={lastPage}
                currentPage={currentPage}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="ticketModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Create Ticket
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form onSubmit={createApi} ref={formRef}>
                <div class="mb-3 row">
                  <label
                    className="subject"
                    class="col-md-4 col-12 col-form-label"
                  >
                    Subject
                  </label>
                  <div class="col-md-8 col-12">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      onInput={(e) => setSubject(e.target.value)}
                      required
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="mb-3 row">
                  <label
                    className="query"
                    class="col-md-4 col-12 col-form-label"
                  >
                    Query
                  </label>
                  <div class="col-md-8 col-12">
                    <textarea
                      cols="4"
                      rows="5"
                      id="query"
                      name="query"
                      required
                      onInput={(e) => setQuery(e.target.value)}
                      className="form-control"
                    ></textarea>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label
                    className="attachfile"
                    class="col-md-4 col-12 col-form-label"
                  >
                    Attach file
                  </label>
                  <div class="col-md-8 col-12">
                    <input
                      type="file"
                      ref={attachRef}
                      name="attach"
                      class="form-control"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className="float-end mt-2">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    ref={closeRef}
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary ms-1">
                    Create Ticket
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Support;
