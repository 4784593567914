import React, { useState, useEffect } from "react";

const Greeting = () => {
  const [greeting, setGreeting] = useState("");
  let user = localStorage.getItem("auth_user");

  if (user) {
    user = JSON.parse(user);
  }

  useEffect(() => {
    const getCurrentGreeting = () => {
      const currentHour = new Date().getHours();

      if (currentHour >= 5 && currentHour < 12) {
        setGreeting("Good morning");
      } else if (currentHour >= 12 && currentHour < 18) {
        setGreeting("Good afternoon");
      } else if (currentHour >= 18 && currentHour < 22) {
        setGreeting("Good evening");
      } else {
        setGreeting("Good night"); // Add Good night greeting
      }
    };

    getCurrentGreeting(); // Set the initial greeting

    // Update the greeting every minute
    const interval = setInterval(getCurrentGreeting, 60000);

    // Clear the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="userName mb-4">
      <h5 className="text-capitalize text-start,">Hi, {user?.name}</h5>
      <div>
        <h3>{greeting}</h3>
      </div>
    </div>
  );
};

export default Greeting;
