
export const units = (state) => {
    let unit = [];
    switch (state) {
        case 'solid':
            unit = ["tonne", "quintal", "kg", "gram", "milligram"];
            break;
        case 'liquid':
            unit = ["liter", "ml"];
            break;
        case 'gas':
            unit = ["tonne", "quintal", "kg", "gram", "milligram", "liter", "ml"];
            break;
    }

    return unit;
}