import './buyers.css';
import Navbar from '../Components/Navbar/Navbar';
import BuyersSidebar from '../Components/BuyerSidebar/BuyersSidebar';
import {Link} from 'react-router-dom'

function Input(props) {
    return (
        <div className="df aic jcb new-input-c">
            <label htmlFor={props.id}>{props.label}</label>
            <input type="text" name={props.id} id={props.id} />
        </div>
    );
} 



export default function BuyersProfileForm() {



    return (
        <section className="buyers-section">
            <Navbar />
            <div className="buyers-section-div">
                <div className="bsd-left">
                    <BuyersSidebar n={1} />
                </div>
                <div className="bsd-right">
                    <div className="buyers-profile-box">
                        <strong className="grey SubAcntHedng">Create Sub Account</strong>
                        <form className='create-form' action="">
                            <Input id={'name'} label={'Name'} />
                            <Input id={'role'} label={'Role'} />
                            <Input id={'user_id'} label={'User Id'} />
                            <Input id={'passoword'} label={'Password'} />

                            <div className='CustRow'>
                                <h3 className='SubAcntHedng'>Access Grant</h3>
                                <div className='CustCol'>
                                <div className='AddressSwitches'>
                                        <p>Address 1</p>
                                        <label class="switchVKG">
                                            <input type="checkbox" />
                                            <span class="sliderVKG roundVKG"></span>
                                        </label>
                                    </div>
                                    <div className='AddressSwitches'>
                                        <p>Address 2</p>
                                        <label class="switchVKG">
                                            <input type="checkbox" />
                                            <span class="sliderVKG roundVKG"></span>
                                        </label>
                                    </div>
                                    <div className='AddressSwitches'>
                                        <p>Address 3</p>
                                        <label class="switchVKG">
                                            <input type="checkbox" />
                                            <span class="sliderVKG roundVKG"></span>
                                        </label>
                                    </div>
                                    <div className='AddressSwitches'>
                                        <p>Address 4</p>
                                        <label class="switchVKG">
                                            <input type="checkbox" />
                                            <span class="sliderVKG roundVKG"></span>
                                        </label>
                                    </div>
                                    
                                </div>
                                <div className='CustCol'>
                                    <div className='AddressSwitches'>
                                        <p>Address 5</p>
                                        <label class="switchVKG">
                                            <input type="checkbox" />
                                            <span class="sliderVKG roundVKG"></span>
                                        </label>
                                    </div>
                                    <div className='AddressSwitches'>
                                        <p>Address 6</p>
                                        <label class="switchVKG">
                                            <input type="checkbox" />
                                            <span class="sliderVKG roundVKG"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                        </form>

                        <div className="btn-con" style={{clear:"both"}}>
                            <Link to='/busers' className="conbtn-g">Create</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
