import './buyers.css' ;
import Navbar, { NavbarAuth } from '../Components/Navbar/Navbar';
import BuyersSidebar from '../Components/BuyerSidebar/BuyersSidebar';
// import RfqCard from '../Components/RfqCard/RfqCard';
import ImgsearchIcon from '../../../assets/search.png';
import { Link } from 'react-router-dom';
import { Margin } from '@mui/icons-material';

function BuyersRfq() {
    return ( 
        <section className="buyers-section">
        <NavbarAuth/> 
        <div className="buyers-section-div">
            <div className="bsd-left">
            <BuyersSidebar n={3}/>
            </div>
            
            <div className="bsd-right bsd-right8247">
                <div className='BlueStpe' style={{background:"#006ff2",color:"white",textAlign:"center",height:"40px",lineHeight:"40px" , borderRadius:"5px 5px 0 0 "}}>Pending Items</div>
                <div className='Outer-bsd-right8247'>
                <div className='searchPendingItem'>
                    <input type="text" placeholder='Search Product With Their Invoice Number, Product Name ' />
                    <img src={ImgsearchIcon} alt="d" />
                </div>
                <div class="table-parent" style={{MarginTop:"40px"}}>
                    <div class="rit3-table">
                        <div class="rit3-header rit3-header82">
                            <div class="">RFQ ID</div>
                            <div class="">RFQ Till Date</div>
                            <div class="">Company</div>
                            <div class="">Need By Date</div>
                            <div class="">Details</div>
                        </div>
                        <div class="rit3-body">
                        <div class="rit3-row rit3-row82">
                                <div class="rit3-info">15666</div>
                                <div class="rit3-info">20/08/2022</div>
                                <div class="rit3-info">Adani ltd</div>
                                <div class="rit3-info">22/08/2022</div>
                                <div class="rit3-info bb-btn"><Link to='/brd'>View</Link></div>
                            </div>
                            <div class="rit3-row rit3-row82">
                                <div class="rit3-info">15666</div>
                                <div class="rit3-info">20/08/2022</div>
                                <div class="rit3-info">Adani ltd</div>
                                <div class="rit3-info">22/08/2022</div>
                                <div class="rit3-info bb-btn"><Link to='/brd'>View</Link></div>
                            </div>
                            <div class="rit3-row rit3-row82">
                                <div class="rit3-info">15666</div>
                                <div class="rit3-info">20/08/2022</div>
                                <div class="rit3-info">Adani ltd</div>
                                <div class="rit3-info">22/08/2022</div>
                                <div class="rit3-info bb-btn"><Link to='/brd'>View</Link></div>
                            </div>
                            <div class="rit3-row rit3-row82">
                                <div class="rit3-info">15666</div>
                                <div class="rit3-info">20/08/2022</div>
                                <div class="rit3-info">Adani ltd</div>
                                <div class="rit3-info">22/08/2022</div>
                                <div class="rit3-info bb-btn"><Link to='/brd'>View</Link></div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </section>
     );
}

export default BuyersRfq;