import React from "react";
import {
  HashRouter as Router,
  Routes,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import Landing from "./pages/landing_page/Landing";
import Shop from "./pages/dashboard/Shop/Shop";
import Faq from "./pages/landing_page/Faq";
import Return from "./pages/landing_page/Return";
import Privacy from "./pages/landing_page/Privacy";
import TnC from "./pages/landing_page/TnC";
import ProductDetails from "./pages/dashboard/Product_Details/ProductDetails";
import Signup from "./pages/dashboard/Login/Signup";
import Otp from "./pages/dashboard/Login/Otp";
import Number from "./pages/dashboard/Login/Number";
import Cart from "./pages/dashboard/Cart/Cart";
import Cart2 from "./pages/dashboard/Cart/Cart2";
import Seller from "./pages/dashboard/Seller/Seller";
import BuyersProfile from "./pages/dashboard/Buyers/BuyersProfile";
import BuyersProfileEdit from "./pages/dashboard/Buyers/BuyersProfileEdit";
import BuyersHistory from "./pages/dashboard/Buyers/BuyersHistory";
import BuyersDocuments from "./pages/dashboard/Buyers/BuyersDocuments";
import BuyersWallet from "./pages/dashboard/Buyers/BuyersWallet";
import BuyersRfq from "./pages/dashboard/Buyers/BuyersRfq";
import Rfqbasic from "./pages/dashboard/Rfq/RfqBasic";
import RfqRequest from "./pages/dashboard/Rfq/RfqRequest";
import RfqSelection from "./pages/dashboard/Rfq/RfqSelection";
import Rfqform from "./pages/dashboard/Rfq/Rfqform";
import Newcheckout from "./pages/dashboard/Checkout/Newcheckout";
import CheckoutPending from "./pages/dashboard/Checkout/CheckoutPending";
import Done from "./pages/dashboard/Checkout/Done";
import Rfqdetails from "./pages/dashboard/Rfq/Rfqdetails";
import BuyersRfqdetails from "./pages/dashboard/Buyers/BuyersRfqdetails";
import Buyersusers from "./pages/dashboard/Buyers/Buyersusers";
import Accountform from "./pages/dashboard/Login/Accountform";
import BuyersProfileForm from "./pages/dashboard/Buyers/BuyersProfileForm";
import MobLog from "./pages/dashboard/LogMobile/MobLog";
import MobNumber from "./pages/dashboard/LogMobile/MobNumber";
import MobOtp from "./pages/dashboard/LogMobile/MobOtp";
import MobDet1 from "./pages/dashboard/LogMobile/MobDet1";
import MobDet2 from "./pages/dashboard/LogMobile/MobDet2";
import ApprovePage from "./pages/dashboard/ApprovePage/ApprovePage";
import Doneslc from "./pages/dashboard/Checkout/Done2";
import ContactNew from "./pages/landing_page/ContactNew";
import Aboutus from "./pages/landing_page/AbouUs";
import Welcome from "./pages/dashboard/Login/Welcome";
import Account from "./pages/dashboard/Login/Account";
import ProfileStep2 from "./pages/dashboard/Login/ProfileStep2";
import ProfileStep3 from "./pages/dashboard/Login/ProfileStep3";
import Pending from "./pages/dashboard/Buyers/Pending";
import FormStep1 from "./pages/dashboard/Rfq/FormStep1";
import FormStep2 from "./pages/dashboard/Rfq/FormStep2";
import FormStep3 from "./pages/dashboard/Rfq/FormStep3";
import FormStep4 from "./pages/dashboard/Rfq/FormStep4";
import FormStep5 from "./pages/dashboard/Rfq/FormStep5";
import AddProducts from "./pages/dashboard/Product_Details/AddProducts";
import RFQ from "./pages/dashboard/Rfq/RFQ";
import OrderDetail from "./pages/dashboard/ApprovePage/OrderDetail";
import ProductDetailsSeller from "./pages/dashboard/Product_Details/ProductDetailsSeller";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import { AuthContext } from "./context/AuthContext";
import { useEffect } from "react";
import { RfqContext } from "./context/RfqContext";
import Support from "./pages/dashboard/support/index";
import SupportDetail from "./pages/dashboard/support/SupportDetail";
import RfqSeller from "./pages/dashboard/Rfq/RfqSeller";
import Address from "./pages/dashboard/Address/Address";
import Dashboard from "./pages/dashboard/Dashboard/Dashboard";
// import LoginImg from '../../../assets/login.png';

function App() {
  const [auth, setAuth] = useState(null);
  const [login, setLogin] = useState(false);
  const [rfq, setRfq] = useState({});

  const checkLogin = () => {
    const isLogin = localStorage.getItem("token_web");
    if (isLogin) {
      setLogin(true);
    } else {
      setLogin(false);
    }
  };

  useEffect(() => {
    checkLogin();
  }, [auth]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      <RfqContext.Provider value={{ rfq, setRfq }}>
        <ToastContainer style={{ zIndex: 9999999 }} />
        <Router>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/product/:slug" element={<ProductDetails />} />
            <Route
              path="/product/:slug/:seller"
              element={<ProductDetailsSeller />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/number" element={<Number />} />
            <Route path="/checkout" element={<Cart />} />
            <Route path="/cart2" element={<Cart2 />} />
            <Route path="/seller/:id" element={<Seller />} />
            <Route path="/contact-us" element={<ContactNew />} />
            <Route path="/about-us" element={<Aboutus />} />
            <Route path="/faqs" element={<Faq />} />
            <Route path="/tnc" element={<TnC />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/return" element={<Return />} />

            <Route path="/profile/:id/show" element={<BuyersProfile />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/account" element={<Buyersusers />} />
            <Route path="/address" element={<Address />} />
            <Route path="/orders" element={<BuyersHistory />} />
            <Route path="/support" element={<Support />} />
            <Route path="/support/:id/detail" element={<SupportDetail />} />
            <Route path="/orders/:id/show" element={<OrderDetail />} />
            <Route path="/documents" element={<BuyersDocuments />} />
            <Route path="/wallet" element={<BuyersWallet />} />
            <Route path="/br" element={<BuyersRfq />} />

            <Route path="/brd" element={<BuyersRfqdetails />} />
            <Route path="/bpf" element={<BuyersProfileForm />} />
            <Route path="/buyeredit" element={<BuyersProfileEdit />} />

            <Route path="/payment" element={<Newcheckout />} />
            <Route path="/payment/:order_id" element={<CheckoutPending />} />
            <Route path="/done" element={<Done />} />
            <Route path="/done2" element={<Doneslc />} />

            <Route path="/af" element={<Accountform />} />

            <Route path="/mlogin" element={<MobLog />} />
            <Route path="/mnum" element={<MobNumber />} />
            <Route path="/motp" element={<MobOtp />} />
            <Route path="/mdet1" element={<MobDet1 />} />
            <Route path="/mdet2" element={<MobDet2 />} />
            <Route path="/approve" element={<ApprovePage />} />

            <Route path="/login" element={<Welcome />} />
            <Route path="/register" element={<Account />} />
            {/* <Route path="/basic" element={<Profile />} /> */}
            <Route path="/complete" element={<ProfileStep2 />} />
            <Route path="/complete/documents" element={<ProfileStep3 />} />
            <Route path="/pending" element={<Pending />} />

            <Route path="/rfqstep1" element={<FormStep1 />} />
            <Route path="/rfqstep2/:rfq_id" element={<FormStep2 />} />
            <Route path="/rfqstep3/:rfq_id" element={<FormStep3 />} />
            <Route path="/rfqstep4/:rfq_id" element={<FormStep4 />} />
            <Route path="/rfqstep5/:rfq_id" element={<FormStep5 />} />

            <Route path="/addproduct" element={<AddProducts />} />
            <Route path="/rfq/:rfq_id" element={<RFQ />} />
            <Route path="/rfq/:id/seller" element={<RfqSeller />} />
            <Route path="/order" element={<OrderDetail />} />
          </Routes>
        </Router>
      </RfqContext.Provider>
    </AuthContext.Provider>
  );
}
export default App;
