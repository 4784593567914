import React from 'react';
import './mob.css';
import OTPInput from "otp-input-react";
import { useState } from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Link } from 'react-router-dom';
import Navbar from '../../landing_page/Navbar';
import Footer from '../../landing_page/Footer';

function MobOtp() {
    const [OTP, setOTP] = useState("");

    return (
        <>
        <Navbar />
        <div className="moblogin-container">
            <Link to={'/mnum'} className="back-arrow"><KeyboardBackspaceIcon /></Link>
            <div className="mob-heading">Enter the 4-digit OTP sent to
                +9175xxxxxxxx</div>

            <form>
                <div className="mob-input-container">
                    <OTPInput value={OTP} onChange={setOTP} autoFocus OTPLength={4} otpType="number" disabled={false} />
                </div>
                <div style={{height:"30px"}} className="df aic jcb">
                    <div className="forget-b">Didn't recieve the otp?</div>
                    <div className="forget-p">Resend</div>
                </div>

                <div  className='forget-b uno-div'><div className="forget-p">Recieve otp on call</div></div>
                <Link to={'/mdet1'} className='conbtn-g mob-btn'>Continue</Link>
            </form>

        </div>
        <Footer /> 
        </>
    )
}

export default MobOtp